import React from 'react';
import { OfferStatus } from '../../api/api.types';

/*
  value - updated state
  undefined - no updates
  null - removed
*/
export interface UserContextProps {
  optimisticLikeForOffer: (offerId: string) => boolean | undefined;
  isOptimisticLikeForOffer: (offerId: string) => boolean;
  optimisticStatusForOffer: (
    offerId: string,
  ) =>
    | { status: OfferStatus | null; isArchived: boolean | null }
    | undefined
    | null;
  isOptimisticStatusForOffer: (offerId: string) => boolean;
}

export const OptimisticUpdatesContext =
  React.createContext<null | UserContextProps>(null);

export const useOptimisticUpdates = () => {
  const context = React.useContext(OptimisticUpdatesContext);
  if (!context) {
    throw new Error(
      `OptimisticUpdatesContext must be used within a OptimisticUpdateProvider`,
    );
  }
  return context;
};
