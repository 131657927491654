import { getByUrl, getOffers } from '../../api/offers/offers';
import { OfferResponse, OfferTypeEnum } from '../../api/api.types';
import { Filter, FilterCondition } from '../../models/filter';
import { useNavigate } from 'react-router-dom';
import { stringifyUrl } from 'query-string';
import { Sort } from '../../models/sort';
import { isOfferType } from '../../models/offer';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { generateOfferLink } from '../../utils/generate-offer-link';

export enum FastSearchActionState {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NO_ERROR = 'NO_ERROR',
}

enum ValueType {
  PHONE_NUMBER = 'PHONE_NUMBER',
  OFFER_URL = 'OFFER_URL',
  UNKNOWN = 'UNKNOWN',
}

interface ValuePropsBase {
  state: string;
  set: (val: string) => void;
}

interface PhoneNumberProps extends ValuePropsBase {
  type: ValueType.PHONE_NUMBER;
  isValid: boolean;
}

interface OfferUrlProps extends ValuePropsBase {
  type: ValueType.OFFER_URL;
  isValid: boolean;
}

interface UnknownValueProps extends ValuePropsBase {
  type: ValueType.UNKNOWN;
}

type ValueProps = PhoneNumberProps | OfferUrlProps | UnknownValueProps;

export const useFastSearch = () => {
  const { t } = useTranslation(['navigation']);
  const navigate = useNavigate();

  const [search, setSearch] = React.useState('');
  const updateValue = (value: string) => {
    setActionState(FastSearchActionState.NO_ERROR);
    setActionError('');
    setSearch(value);
  };
  const valueProps = createValueProps({ value: search, setValue: updateValue });

  const [actionState, setActionState] = React.useState(
    FastSearchActionState.NO_ERROR,
  );
  const [actionError, setActionError] = React.useState('');

  const {
    data: offers,
    isLoading: isOffersLoading,
    isError: isOffersError,
  } = useQuery(
    ['fastSearch', 'phoneNumber', search],
    () => requestMatchingOffers({ phoneNumber: search }),
    { enabled: isCorrectPhoneNumber(search) },
  );

  const {
    data: urlOffer,
    isLoading: isUrlOfferLoading,
    isError: isUrlOfferError,
  } = useQuery(['fastSearch', 'url', search], () => getByUrl(search), {
    enabled: isCorrectLink(search),
    retry: 1,
    retryDelay: 0,
  });

  const matchingOffers = [
    urlOffer?.offer,
    ...(offers?.results.map((r) => r.offer) ?? []),
  ].filter((x) => x);

  const isLoading =
    (isCorrectPhoneNumber(search) && isOffersLoading) ||
    (isCorrectLink(search) && isUrlOfferLoading);

  const performFastSearchAction = async () => {
    setActionState(FastSearchActionState.LOADING);

    if (valueProps.type === ValueType.PHONE_NUMBER && !valueProps.isValid) {
      setActionState(FastSearchActionState.ERROR);
      setActionError(
        t('navigation:userMenu.fastSearch.errors.incorrectPhoneNumber'),
      );
      return Promise.reject(
        t('navigation:userMenu.fastSearch.errors.incorrectPhoneNumber'),
      );
    }
    if (valueProps.type === ValueType.OFFER_URL && !valueProps.isValid) {
      setActionState(FastSearchActionState.ERROR);
      setActionError(t('navigation:userMenu.fastSearch.errors.incorrectLink'));
      return Promise.reject(
        t('navigation:userMenu.fastSearch.errors.incorrectLink'),
      );
    }

    try {
      let data = matchingOffers;
      if (isLoading) {
        if (valueProps.type === ValueType.PHONE_NUMBER) {
          data = (
            await requestMatchingOffers({ phoneNumber: search })
          ).results.map((r) => r.offer);
        } else if (valueProps.type === ValueType.OFFER_URL) {
          data = [(await getByUrl(search)).offer];
        }
      }

      if (data) {
        if (data.length === 1) {
          navigate(generateOfferPath({ offerList: data }));
        } else if (data.length > 0) {
          navigate(generateListPath({ phoneNumber: search }));
        } else {
          const errorText = (() => {
            switch (valueProps.type) {
              case ValueType.PHONE_NUMBER:
                return t(
                  'navigation:userMenu.fastSearch.errors.noValuePhoneNumber',
                );
              case ValueType.OFFER_URL:
                return t('navigation:userMenu.fastSearch.errors.noValueLink');
              case ValueType.UNKNOWN:
                return t('navigation:userMenu.fastSearch.errors.unknownValue');
              default:
                // case not possible
                return ' ';
            }
          })();

          setActionState(FastSearchActionState.ERROR);
          setActionError(errorText);
          return Promise.reject(errorText);
        }
      } else {
        setActionState(FastSearchActionState.ERROR);
        setActionError(t('navigation:userMenu.fastSearch.errors.serverError'));
        return Promise.reject(
          t('navigation:userMenu.fastSearch.errors.serverError'),
        );
      }
    } catch (error: any) {
      const errorString =
        error?.response?.status === 404
          ? t('navigation:userMenu.fastSearch.errors.noValueLink')
          : t('navigation:userMenu.fastSearch.errors.serverError');
      setActionState(FastSearchActionState.ERROR);
      setActionError(errorString);
      return Promise.reject(errorString);
    }
  };

  const hint = (() => {
    if (isLoading || actionState === FastSearchActionState.LOADING) {
      return t('navigation:userMenu.fastSearch.hints.loading');
    }
    if (matchingOffers.length === 0) {
      if (valueProps.type === ValueType.UNKNOWN) {
        return t('navigation:userMenu.fastSearch.hints.typePhoneNumberOrLink');
      } else if (valueProps.type === ValueType.OFFER_URL) {
        if (valueProps.isValid) {
          return t('navigation:userMenu.fastSearch.hints.typeOtherLink');
        } else {
          return t('navigation:userMenu.fastSearch.hints.typeLink');
        }
      } else if (valueProps.type === ValueType.PHONE_NUMBER) {
        if (valueProps.isValid) {
          return t('navigation:userMenu.fastSearch.hints.typeOtherPhoneNumber');
        } else {
          return t('navigation:userMenu.fastSearch.hints.typePhoneNumber');
        }
      }
    } else if (matchingOffers.length === 1) {
      return t('navigation:userMenu.fastSearch.hints.pressEnterToGoToOffer');
    } else if (matchingOffers.length > 1) {
      return t('navigation:userMenu.fastSearch.hints.pressEnterToGoToList');
    }
    return ' ';
  })();

  const clear = () => {
    setActionState(FastSearchActionState.NO_ERROR);
    setSearch('');
    setActionError('');
  };

  return {
    action: {
      perform: performFastSearchAction,
      isLoading: actionState === FastSearchActionState.LOADING,
      isError: actionState === FastSearchActionState.ERROR,
      error: actionError,
    },
    value: valueProps,
    offers: {
      state: matchingOffers,
      isLoading: isLoading,

      isError:
        (isCorrectPhoneNumber(search) && isOffersError) ||
        (isCorrectLink(search) && isUrlOfferError),
    },
    clear,
    hint,
  };
};

const createFilters = ({ phoneNumber }: { phoneNumber: string }): Filter[] => {
  return [
    {
      field: 'contact_number',
      value: formatPhoneNumber(phoneNumber),
      condition: FilterCondition.EQUAL,
    },
  ];
};

const SORT_BY_DATE_DESC = [{ field: 'date_scraped', ascending: false } as Sort];

const generateOfferPath = ({ offerList }: { offerList: OfferResponse[] }) => {
  const [offerType, id] = extractOfferTypeAndId(offerList);
  return generateOfferLink({ id, offerType });
};

const requestMatchingOffers = async ({
  phoneNumber,
}: {
  phoneNumber: string;
}) => {
  return await getOffers(
    Object.values(OfferTypeEnum),
    1,
    5,
    createFilters({ phoneNumber }),
    SORT_BY_DATE_DESC,
  );
};

const createValueProps = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (val: string) => void;
}): ValueProps => {
  const base = {
    state: value,
    set: setValue,
  };
  if (canBeLink(value)) {
    return {
      type: ValueType.OFFER_URL,
      isValid: isCorrectLink(value),
      ...base,
    };
  }
  if (canBePhoneNumber(value)) {
    return {
      type: ValueType.PHONE_NUMBER,
      isValid: isCorrectPhoneNumber(value),
      ...base,
    };
  }
  return {
    type: ValueType.UNKNOWN,
    ...base,
  };
};

const canBeLink = (value: string) => {
  if (
    value.startsWith('http') ||
    value.startsWith('https') ||
    value.startsWith('www')
  ) {
    return true;
  }
  try {
    new URL(value);
    return true;
  } catch (error) {
    return false;
  }
};

const isCorrectLink = (value: string) => {
  try {
    new URL(value);
    return true;
  } catch (error) {
    return false;
  }
};

const canBePhoneNumber = (value: string) => {
  const noSpaces = value.replaceAll(' ', '');
  return !!noSpaces && /^\+?\d*$/.test(noSpaces);
};

const formatPhoneNumber = (value: string) => {
  const noSpaces = value.replaceAll(' ', '');
  if (noSpaces.length === 12 && noSpaces.startsWith('+48')) {
    return noSpaces;
  }
  if (noSpaces.length === 11 && noSpaces.startsWith('48')) {
    return `+${noSpaces}`;
  }
  return `+48${noSpaces}`;
};

const isCorrectPhoneNumber = (value: string) => {
  const formatted = formatPhoneNumber(value);
  return formatted.match(/^\+?\d{9,11}$/) !== null;
};

const extractOfferTypeAndId = (offerList: OfferResponse[]) => {
  const first = offerList[0] as Record<string, any>;
  if (!('offer_type' in first) || !('id' in first)) {
    throw new Error('Incorrect response');
  }
  if (!isOfferType(first.offer_type)) {
    throw new Error('Incorrect response');
  }
  if (!(typeof first.id === 'string')) {
    throw new Error('Incorrect response');
  }
  return [first.offer_type as OfferTypeEnum, first.id] as const;
};

const generateListPath = ({ phoneNumber }: { phoneNumber: string }) => {
  return stringifyUrl({
    url: `/offers/list`,
    query: {
      page: 1,
      filters: JSON.stringify(createFilters({ phoneNumber })),
      sorts: JSON.stringify(SORT_BY_DATE_DESC),
      reload: true,
    },
  });
};
