import React from 'react';
import { classNames } from '../../../../style/class-names';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAnalytics } from '../../../../context/analytics/use-analytics';
import { useOptimisticUpdateManager } from '../../../../context/offer-optimistic-update/use-optimistic-update-manager';
import { useOptimisticUpdates } from '../../../../context/offer-optimistic-update/use-optimistic-updates';
import {
  deleteOfferStatus,
  postOfferStatus,
} from '../../../../api/offers/offers';
import { ANALYTICS_EVENTS } from '../../../../constants/analytics';
import {
  OfferStatus,
  OfferStatusResponse,
  OfferTypeEnum,
} from '../../../../api/api.types';
import {
  OFFER_STATUS_BACKGROUND_LIGHT_CLASSES,
  OFFER_STATUS_CLASSES,
} from '../../../../constants/offer-status-icons';
import OfferStatusIcon from '../../../icons/offer-status-icon';
import { Menu } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useOffersInvalidation } from '../../../../hooks/use-invalidation/use-offers-invalidation';

interface Props {
  offerType: OfferTypeEnum;
  offerId: string;
  selectedOfferStatus: OfferStatusResponse | null;
  status: OfferStatus;
}

const AddToStatusAction = ({
  offerType,
  offerId,
  selectedOfferStatus,
  status,
}: Props) => {
  const { t } = useTranslation(['common']);
  const invalidateOffers = useOffersInvalidation();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();
  const { setOfferStatus } = useOptimisticUpdateManager();
  const { optimisticStatusForOffer, isOptimisticStatusForOffer } =
    useOptimisticUpdates();
  const { mutate: addStatus } = useMutation(postOfferStatus, {
    onMutate: async (data) => {
      await queryClient.cancelQueries(['offers']);
      await queryClient.cancelQueries(['offerStatuses', offerType, offerId]);
      return data;
    },
    onSuccess: async (_, { body }) => {
      setOfferStatus(offerId, body.status, false);
      await invalidateOffers();
      await queryClient.invalidateQueries([
        'offerStatuses',
        offerType,
        offerId,
      ]);
      await analytics.track(ANALYTICS_EVENTS.OFFER_STATUS_ADDED, {
        offerType,
        offerId: offerId,
      });
    },
  });
  const onAddOfferStatus = (status: OfferStatus) => {
    addStatus({ offerType, offerId: offerId, body: { status } });
  };

  const { mutate: removeStatus } = useMutation(deleteOfferStatus, {
    onMutate: async (data) => {
      await queryClient.cancelQueries(['offers']);
      await queryClient.cancelQueries(['offerStatuses', offerType, offerId]);
      return data;
    },
    onSuccess: async () => {
      setOfferStatus(offerId, null, false);
      await invalidateOffers();
      await queryClient.invalidateQueries([
        'offerStatuses',
        offerType,
        offerId,
      ]);
      await analytics.track(ANALYTICS_EVENTS.OFFER_STATUS_DELETED, {
        offerType,
        offerId: offerId,
      });
    },
  });
  const onRemoveOfferStatus = () => {
    removeStatus({ offerType, offerId: offerId });
  };

  const currentStatus = isOptimisticStatusForOffer(offerId)
    ? optimisticStatusForOffer(offerId)
    : {
        status: selectedOfferStatus?.offer_status,
        isArchived: !!selectedOfferStatus?.archived_at,
      };

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={() => {
            currentStatus?.status === status
              ? onRemoveOfferStatus()
              : onAddOfferStatus(status);
          }}
          className={classNames(
            active
              ? 'bg-gray-100 text-gray-900'
              : currentStatus?.status === status
              ? OFFER_STATUS_BACKGROUND_LIGHT_CLASSES[status]
              : '',
            'flex w-full items-center px-4 py-2 text-sm',
          )}
        >
          <span className={classNames('mr-2')}>
            <OfferStatusIcon offerStatus={status} size={'md'} />
          </span>
          <span
            className={classNames(
              !active && currentStatus?.status === status
                ? OFFER_STATUS_CLASSES[status]
                : 'text-gray-700',
              'bg-inherit',
            )}
          >
            {t(`common:offerStatuses.values.${status}`)}
          </span>
        </button>
      )}
    </Menu.Item>
  );
};

export default AddToStatusAction;
