import React from 'react';
import { AnonymousUser, AuthenticatedUser, User } from '../../models/user';
import { getMe, postLogout, refreshToken } from '../../api/auth/auth';
import { UserContext } from './use-user';
import { AuthContext } from './use-auth';
import { axios } from '../../api/axios';
import { DEFAULT_SETTINGS } from '../../models/user-settings';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/spinner/spinner';
import { useAnalytics, useAnalyticsCallback } from '../analytics/use-analytics';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import ErrorToast from '../../components/toasts/error-toast';
import { LoginErrors } from '../../pages/login/login';

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currUser, setCurrUser] = React.useState<User>(new AnonymousUser());
  const [isLoading, setIsLoading] = React.useState(true);
  const { afterTrack } = useAnalyticsCallback();
  const [interceptor, setInterceptor] = React.useState<number | null>(null);
  const analytics = useAnalytics();
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { t } = useTranslation(['auth', 'common']);

  const setupInterceptor = React.useCallback(() => {
    setInterceptor(
      axios.interceptors.response.use(
        (resp) => resp,
        async (error) => {
          const response = error.response;

          if (response) {
            if (error.response?.detail === LoginErrors.USER_BANNED) {
              toast.custom((toast) => (
                <ErrorToast
                  {...toast}
                  title={t('auth:alerts.loginUserBanned.title')}
                  body={t('auth:alerts.loginUserBanned.body')}
                />
              ));
              return Promise.reject(error);
            }

            if (error.response.data?.detail !== 'Not authenticated') {
              return Promise.reject(error);
            }
            if (error.config.__isRetryRequest) {
              setCurrUser(new AnonymousUser());
              return Promise.reject(error);
            }
            try {
              await refreshToken();
            } catch {
              setCurrUser(new AnonymousUser());
              return Promise.reject(error);
            }
            error.config.__isRetryRequest = true;
            return axios.request(error.response.config);
          }
          return Promise.reject(error);
        },
      ),
    );
  }, []);

  const loadUser = React.useCallback(async () => {
    setupInterceptor();
    try {
      const user = await queryClient.fetchQuery(['user'], getMe);
      const authUser = new AuthenticatedUser({
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        settings: { ...DEFAULT_SETTINGS, ...user.settings },
        selectedOrganization: {
          organizationId: user.selected_organization.organization_id,
          type: user.selected_organization.type,
          name: user.selected_organization.name,
          logoUrl: user.selected_organization.logo_url ?? null,
          phoneNumber: user.selected_organization.phone_number ?? null,
          address: user.selected_organization.address ?? null,
          website: user.selected_organization.website ?? null,
        },
        avatarUrl: user.avatar_url ?? null,
        userId: user.user_id,
        hasEmptySettings: Object.keys(user.settings).length === 0,
        performedActions: user.performed_actions ?? [],
        phoneNumber: user.phone_number ? user.phone_number : null,
        enabledFeatureFlags: user.enabled_feature_flags ?? [],
        jobTitle: user.job_title ?? null,
        organizationMemberPrivilege: user.organization_member_privilege,
        subscription: {
          usersLimit: user.app_subscription.users_limit,
          expiresAt: new Date(user.app_subscription.expires_at),
          tier: user.app_subscription.subscription_tier,
        },
        team: user.team
          ? { teamId: user.team.team_id, name: user.team.name }
          : null,
        communicationConsents: {
          isAppConsentGiven:
            user.communication_consents?.is_app_consent_given ?? false,
          isMarketingConsentGiven:
            user.communication_consents?.is_marketing_consent_given ?? false,
        },
      });
      setCurrUser(authUser);
      if (i18n.language !== authUser.settings.language) {
        await i18n.changeLanguage(authUser.settings.language);
      }
      await analytics.reset();
      await analytics.identify(authUser.userId, {
        organizationId: authUser.selectedOrganization.organizationId,
        language: authUser.settings.language,
        email: authUser.email,
        firstName: authUser.firstName,
        lastName: authUser.lastName,
        defaultLocation: authUser.settings.defaultLocation
          ? JSON.stringify(authUser.settings.defaultLocation)
          : null,
      });
    } catch (e: any) {
      const detail = e.response?.data?.detail;
      if (detail === LoginErrors.USER_BANNED) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.loginUserBanned.title')}
            body={t('auth:alerts.loginUserBanned.body')}
          />
        ));
      } else if (detail === LoginErrors.USER_DEACTIVATED_BY_ORGANIZATION) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.loginUserDeactivatedByOrganization.title')}
            body={t('auth:alerts.loginUserDeactivatedByOrganization.body')}
          />
        ));
      }

      setCurrUser(new AnonymousUser());
    } finally {
      setIsLoading(false);
    }
  }, [i18n, setupInterceptor, analytics, queryClient]);

  const logoutUser = React.useCallback(async () => {
    await postLogout();
    await analytics.reset();
    setCurrUser(new AnonymousUser());
    if (interceptor !== null) {
      axios.interceptors.response.eject(interceptor);
      setInterceptor(null);
    }
    queryClient.clear();
  }, [interceptor, analytics, queryClient]);

  React.useEffect(() => {
    loadUser();
  }, []);

  React.useEffect(() => {
    const func = (a: string) =>
      setCurrUser((prev) => prev.withPerformedAction(a));
    afterTrack(func);
  }, []);

  return (
    <UserContext.Provider value={{ user: currUser }}>
      <AuthContext.Provider value={{ loadUser, logoutUser }}>
        {!isLoading ? children : <Spinner />}
      </AuthContext.Provider>
    </UserContext.Provider>
  );
};
