import { Switch } from '@headlessui/react';
import React from 'react';
import { InputLabelProps } from '../shared/input-label';
import { classNames } from '../../../style/class-names';
import RequiredIcon from '../../explanaion-icons/required-icon';

interface Props extends Omit<InputLabelProps, 'labelFor'> {
  value: boolean;
  onChange: (newValue: boolean) => void;
  className?: string;
  id: string;
  isRequired?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

const ToggleInputLabeled = ({
  label,
  labelClassName = '',
  value = false,
  onChange,
  id,
  className = '',
  isError = false,
  errorMessage = '',
  isRequired = false,
}: Props) => {
  return (
    <>
      <Switch.Group
        as="div"
        className={`flex items-center ${className}`}
        id={id}
      >
        <Switch
          checked={value}
          onChange={onChange}
          className={classNames(
            value ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              value ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          >
            <span
              className={classNames(
                value
                  ? 'opacity-0 duration-100 ease-out'
                  : 'opacity-100 duration-200 ease-in',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
              )}
              aria-hidden="true"
            >
              <svg
                className="h-3 w-3 text-gray-400"
                fill="none"
                viewBox="0 0 12 12"
              >
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              className={classNames(
                value
                  ? 'opacity-100 duration-200 ease-in'
                  : 'opacity-0 duration-100 ease-out',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
              )}
              aria-hidden="true"
            >
              <svg
                className="h-3 w-3 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
        <span className={`ml-4 flex flex-grow flex-col ${labelClassName}`}>
          <Switch.Label
            as="span"
            className="flex flex-row text-sm font-medium text-gray-700"
            passive
          >
            {label}
            {isRequired && (
              <RequiredIcon size="xs" classNameWrapper="ml-2 self-start" />
            )}
          </Switch.Label>
        </span>
      </Switch.Group>
      {isError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </>
  );
};

export default ToggleInputLabeled;
