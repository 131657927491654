import React from 'react';
import { OfferStatus } from '../../api/api.types';

export interface UserContextProps {
  setLikedStatus: (offerId: string, likedStatus: boolean) => void;
  removeLikedStatus: (offerId: string) => void;
  removeAllLikedStatuses: () => void;
  setOfferStatus: (
    offerId: string,
    offerStatus: OfferStatus | null,
    isArchived: boolean | null,
  ) => void;
  removeOfferStatus: (offerId: string) => void;
  removeAllOfferStatuses: () => void;
}

export const OptimisticUpdateManagerContext =
  React.createContext<null | UserContextProps>(null);

export const useOptimisticUpdateManager = () => {
  const context = React.useContext(OptimisticUpdateManagerContext);
  if (!context) {
    throw new Error(
      `useOptimisticUpdateManager must be used within a OptimisticUpdateProvider`,
    );
  }
  return context;
};
