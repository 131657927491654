import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Filter,
  FilterCondition,
  filtersToForm,
  formToFilterList,
  GEO_SHAPE,
  GREATER_OR_EQUAL_THAN,
  LESSER_OR_EQUAL_THAN,
} from '../../models/filter';
import React from 'react';
import { useUser } from '../auth/use-user';
import { formToSortList, Sort, sortsToForm } from '../../models/sort';
import { MarketTypeEnum, OfferTypeEnum } from '../../api/api.types';
import { offersTypesToForm } from '../../models/offer';
import { useOffersSearchFormValidation } from './offers-search-form-validation';
import { countFilters, useRevalidation } from './search-form-helpers';

const SearchFormContext = React.createContext<
  ReturnType<typeof useSearchFormHelper>
>({} as any);

interface SearchFormProps {
  validateBeforeSubmit?: boolean;
}

interface SearchFormProviderProps extends SearchFormProps {
  children: React.ReactNode;
}

const SearchFormProvider = ({
  children,
  validateBeforeSubmit,
}: SearchFormProviderProps) => {
  const value = useSearchFormHelper({ validateBeforeSubmit });

  return (
    <SearchFormContext.Provider value={value}>
      {children}
    </SearchFormContext.Provider>
  );
};

const useSearchForm = () => {
  const context = React.useContext(SearchFormContext);
  if (context === undefined) {
    throw new Error('searchForm must be used within a SearchFormProvider');
  }
  return context;
};

const useSearchFormHelper = ({
  validateBeforeSubmit = false,
}: SearchFormProps = {}) => {
  const user = useUser();
  const schema = useOffersSearchFormValidation();

  const {
    control,
    watch,
    trigger,
    reset: resetForm,
    getValues,
    ...rest
  } = useForm<SearchFormValues>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const reset = (sort: Sort[], filters: Filter[], types: OfferTypeEnum[]) => {
    resetForm({
      ...sortsToForm(sort),
      ...filtersToForm(filters, user.settings.dateFormat),
      ...offersTypesToForm(types),
    });
  };

  useRevalidation({ control, watch, trigger, validateBeforeSubmit });

  const getFormValues = React.useMemo(
    () => async () => {
      const res = await trigger();
      if (!res) {
        return;
      }

      const { sort, ...rest } = schema.cast(getValues()) as any;
      return {
        filters: formToFilterList(rest),
        sorts: formToSortList(sort),
      };
    },
    [],
  );

  return {
    control,
    watch,
    trigger,
    schema,
    reset,
    getValues,
    getFormValues,
    filtersCount: Math.max(countFilters(getValues()), 0),
    ...rest,
  };
};

const SEARCH_FROM_FIELDS = {
  LOCATION_KEY: 'location',
  OFFERS_TYPES_KEY: 'offersTypes',
  FLOOR_LOWER_BOUND_KEY: 'floor_no_lower_bound',
  FLOOR_UPPER_BOUND_KEY: 'floor_no_upper_bound',
  LEVELS_IN_BUILDING_LOWER_BOUND_KEY: 'total_levels_in_building_lower_bound',
  LEVELS_IN_BUILDING_UPPER_BOUND_KEY: 'total_levels_in_building_upper_bound',
} as const;

const SEARCH_FORM_KEYS = {
  /* general */
  market_type: `market_type.${FilterCondition.EQUAL}`,
  sort: `sort`,
  offersTypes: SEARCH_FROM_FIELDS.OFFERS_TYPES_KEY,
  issuer_type: `issuer_type.${FilterCondition.IN}`,
  /* location */
  address: `address.${FilterCondition.EQUAL}`,
  location: `${SEARCH_FROM_FIELDS.LOCATION_KEY}.${FilterCondition.GEO_SHAPE}`,
  proximity: `${SEARCH_FROM_FIELDS.LOCATION_KEY}.${FilterCondition.PROXIMITY}`,
  /* offer */
  contact_number: `contact_number.${FilterCondition.EQUAL}`,
  tag: `tag.${FilterCondition.IN}`,
  title: `title.${FilterCondition.ILIKE}`,
  description_clean_text: `description_clean_text.${FilterCondition.ILIKE}`,
  date_scraped: {
    from: `date_scraped.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `date_scraped.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  date_smart_last_seen: {
    lastNDays: `date_smart_last_seen.${FilterCondition.LAST_N_DAYS}`,
    from: `date_smart_last_seen.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `date_smart_last_seen.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  is_active: `is_active.${FilterCondition.EQUAL}`,
  /* price */
  price: {
    from: `price.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `price.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  price_per_meter: {
    from: `price_per_meter.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `price_per_meter.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  /* size */
  property_size: {
    from: `property_size.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `property_size.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  rooms: {
    from: `rooms.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `rooms.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  bathroom_count: {
    from: `bathroom_count.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `bathroom_count.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  /* building */
  building_type: `building_type.${FilterCondition.IN}`,
  house_type: `house_type.${FilterCondition.IN}`,
  building_stage: `building_stage.${FilterCondition.IN}`,
  building_material: `building_material.${FilterCondition.IN}`,
  total_levels_in_building: {
    from: `${SEARCH_FROM_FIELDS.LEVELS_IN_BUILDING_LOWER_BOUND_KEY}.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `${SEARCH_FROM_FIELDS.LEVELS_IN_BUILDING_UPPER_BOUND_KEY}.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  location_type: `location_type.${FilterCondition.IN}`,
  levels: `levels.${FilterCondition.IN}`,
  parking_lots: {
    from: `parking_lots.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `parking_lots.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  /* utilities */
  water_access: `water_access.${FilterCondition.IN}`,
  heated_water: `heated_water.${FilterCondition.IN}`,
  sewage: `sewage.${FilterCondition.IN}`,
  has_electricity_access: `has_electricity_access.${FilterCondition.EQUAL}`,
  has_gas_access: `has_gas_access.${FilterCondition.EQUAL}`,
  has_cable_internet_access: `has_cable_internet_access.${FilterCondition.EQUAL}`,
  has_cable_television_access: `has_cable_television_access.${FilterCondition.EQUAL}`,
  has_cable_telephone_access: `has_cable_telephone_access.${FilterCondition.EQUAL}`,
  has_high_voltage_plug: `has_high_voltage_plug.${FilterCondition.EQUAL}`,
  heating: `heating.${FilterCondition.IN}`,
  has_anti_theft_protection: `has_anti_theft_protection.${FilterCondition.EQUAL}`,
  has_air_conditioning: `has_air_conditioning.${FilterCondition.EQUAL}`,
  /* land */
  access_road: `access_road.${FilterCondition.IN}`,
  fence: `fence.${FilterCondition.IN}`,
  land_type: `land_type.${FilterCondition.IN}`,
  land_shape: `land_shape.${FilterCondition.IN}`,
  land_length: {
    from: `land_length.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `land_length.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  land_width: {
    from: `land_width.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `land_width.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  /* law */
  ownership_type: `ownership_type.${FilterCondition.IN}`,
  has_land_and_mortgage_register: `has_land_and_mortgage_register.${FilterCondition.EQUAL}`,
  /* house */
  year_built: {
    from: `year_built.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `year_built.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  windows_material: `windows_material.${FilterCondition.IN}`,
  roof_material: `roof_material.${FilterCondition.IN}`,
  kitchen_type: `kitchen_type.${FilterCondition.IN}`,
  attic: `attic.${FilterCondition.IN}`,
  to_be_renovated: `to_be_renovated.${FilterCondition.EQUAL}`,
  floor_type: `floor_type.${FilterCondition.IN}`,
  construction_type: `construction_type.${FilterCondition.IN}`,
  room_type: `room_type.${FilterCondition.IN}`,
  auction_offer_type: `auction_offer_type.${FilterCondition.IN}`,
  /* apartments  */
  is_furnished: `is_furnished.${FilterCondition.EQUAL}`,
  has_elevator: `has_elevator.${FilterCondition.EQUAL}`,
  has_basement: `has_basement.${FilterCondition.EQUAL}`,
  has_terrace: `has_terrace.${FilterCondition.EQUAL}`,
  has_balcony: `has_balcony.${FilterCondition.EQUAL}`,
  is_gated_community: `is_gated_community.${FilterCondition.EQUAL}`,
  parking: `parking.${FilterCondition.IN}`,
  floor_no: {
    from: `${SEARCH_FROM_FIELDS.FLOOR_LOWER_BOUND_KEY}.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
    to: `${SEARCH_FROM_FIELDS.FLOOR_UPPER_BOUND_KEY}.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  },
  /* public transport */
  distanceFromSubway: `distance_from_subway.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  distanceFromTram: `distance_from_tram.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
  distanceFromBus: `distance_from_bus.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
} as const;

interface SearchFormValues {
  [SEARCH_FORM_KEYS.market_type]?: MarketTypeEnum;
  [SEARCH_FORM_KEYS.sort]: string; // ?
  [SEARCH_FROM_FIELDS.OFFERS_TYPES_KEY]: OfferTypeEnum[];
  [SEARCH_FORM_KEYS.issuer_type]?: string;
  [SEARCH_FORM_KEYS.address]?: any; // array of one item of shape { label: string, value: Address }
  [SEARCH_FROM_FIELDS.LOCATION_KEY]: {
    [GEO_SHAPE]: [number, number][][] | null;
  };
  [SEARCH_FORM_KEYS.proximity]?: number;
  [SEARCH_FORM_KEYS.contact_number]?: string;
  [SEARCH_FORM_KEYS.tag]?: string;
  [SEARCH_FORM_KEYS.title]?: string;
  [SEARCH_FORM_KEYS.description_clean_text]?: string;
  [SEARCH_FORM_KEYS.date_scraped.from]?: string; // ?
  [SEARCH_FORM_KEYS.date_scraped.to]?: string; // ?
  [SEARCH_FORM_KEYS.date_smart_last_seen.lastNDays]?: number;
  [SEARCH_FORM_KEYS.date_smart_last_seen.from]?: number;
  [SEARCH_FORM_KEYS.date_smart_last_seen.to]?: number;
  [SEARCH_FORM_KEYS.is_active]?: boolean;
  [SEARCH_FORM_KEYS.price.from]?: number;
  [SEARCH_FORM_KEYS.price.to]?: number;
  [SEARCH_FORM_KEYS.property_size.from]?: number;
  [SEARCH_FORM_KEYS.property_size.to]?: number;
  [SEARCH_FORM_KEYS.rooms.from]?: number;
  [SEARCH_FORM_KEYS.rooms.to]?: number;
  [SEARCH_FORM_KEYS.bathroom_count.from]?: number;
  [SEARCH_FORM_KEYS.bathroom_count.to]?: number;
  [SEARCH_FORM_KEYS.building_type]?: string;
  [SEARCH_FORM_KEYS.house_type]?: string;
  [SEARCH_FORM_KEYS.building_stage]?: string;
  [SEARCH_FORM_KEYS.building_material]?: string;
  [SEARCH_FROM_FIELDS.LEVELS_IN_BUILDING_LOWER_BOUND_KEY]: {
    [GREATER_OR_EQUAL_THAN]?: number;
  };
  [SEARCH_FROM_FIELDS.LEVELS_IN_BUILDING_UPPER_BOUND_KEY]: {
    [LESSER_OR_EQUAL_THAN]?: number;
  };
  [SEARCH_FORM_KEYS.location_type]?: string;
  [SEARCH_FORM_KEYS.levels]?: string; // ?
  [SEARCH_FORM_KEYS.parking_lots.from]?: number;
  [SEARCH_FORM_KEYS.parking_lots.to]?: number;
  [SEARCH_FORM_KEYS.water_access]?: string;
  [SEARCH_FORM_KEYS.heated_water]?: string;
  [SEARCH_FORM_KEYS.sewage]?: string;
  [SEARCH_FORM_KEYS.has_electricity_access]?: boolean;
  [SEARCH_FORM_KEYS.has_gas_access]?: boolean;
  [SEARCH_FORM_KEYS.has_cable_internet_access]?: boolean;
  [SEARCH_FORM_KEYS.has_cable_television_access]?: boolean;
  [SEARCH_FORM_KEYS.has_cable_telephone_access]?: boolean;
  [SEARCH_FORM_KEYS.has_high_voltage_plug]?: boolean;
  [SEARCH_FORM_KEYS.heating]?: string;
  [SEARCH_FORM_KEYS.has_anti_theft_protection]?: boolean;
  [SEARCH_FORM_KEYS.has_air_conditioning]?: boolean;
  [SEARCH_FORM_KEYS.access_road]?: string;
  [SEARCH_FORM_KEYS.fence]?: string;
  [SEARCH_FORM_KEYS.land_type]?: string;
  [SEARCH_FORM_KEYS.land_shape]?: string;
  [SEARCH_FORM_KEYS.land_length.from]?: number;
  [SEARCH_FORM_KEYS.land_length.to]?: number;
  [SEARCH_FORM_KEYS.land_width.from]?: number;
  [SEARCH_FORM_KEYS.land_width.to]?: number;
  [SEARCH_FORM_KEYS.ownership_type]?: string;
  [SEARCH_FORM_KEYS.has_land_and_mortgage_register]?: boolean;
  [SEARCH_FORM_KEYS.year_built.from]?: number;
  [SEARCH_FORM_KEYS.year_built.to]?: number;
  [SEARCH_FORM_KEYS.windows_material]?: string;
  [SEARCH_FORM_KEYS.roof_material]?: string;
  [SEARCH_FORM_KEYS.kitchen_type]?: string;
  [SEARCH_FORM_KEYS.attic]?: string;
  [SEARCH_FORM_KEYS.to_be_renovated]?: boolean;
  [SEARCH_FORM_KEYS.floor_type]?: string;
  [SEARCH_FORM_KEYS.construction_type]?: string;
  [SEARCH_FORM_KEYS.room_type]?: string;
  [SEARCH_FORM_KEYS.auction_offer_type]?: string;
  [SEARCH_FORM_KEYS.is_furnished]?: boolean;
  [SEARCH_FORM_KEYS.has_elevator]?: boolean;
  [SEARCH_FORM_KEYS.has_basement]?: boolean;
  [SEARCH_FORM_KEYS.has_terrace]?: boolean;
  [SEARCH_FORM_KEYS.has_balcony]?: boolean;
  [SEARCH_FORM_KEYS.is_gated_community]?: boolean;
  [SEARCH_FORM_KEYS.parking]?: string;
  [SEARCH_FROM_FIELDS.FLOOR_LOWER_BOUND_KEY]: {
    [GREATER_OR_EQUAL_THAN]?: number;
  };
  [SEARCH_FROM_FIELDS.FLOOR_UPPER_BOUND_KEY]: {
    [LESSER_OR_EQUAL_THAN]?: number;
  };
  [SEARCH_FORM_KEYS.distanceFromSubway]?: number;
  [SEARCH_FORM_KEYS.distanceFromTram]?: number;
  [SEARCH_FORM_KEYS.distanceFromBus]?: number;
}

export {
  useSearchForm,
  SearchFormProvider,
  SEARCH_FORM_KEYS,
  SEARCH_FROM_FIELDS,
};
export type { SearchFormValues };
