import OfferCardSkeleton from '../offer/offer-card-skeleton';
import PaginationItemsSummary from '../pagination/pagination-items-summary';
import { BuildingOfficeIcon } from '@heroicons/react/20/solid';
import OfferCard from '../offer/offer-card';
import { AVMResult, OfferListResponse } from '../../api/api.types';
import Pagination from '../pagination/pagination';
import React from 'react';
import { useUser } from '../../context/auth/use-user';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface Props {
  isLoading: boolean;
  data?: OfferListResponse;
  page: number;
}

const OfferList = ({ isLoading, data, page }: Props) => {
  const user = useUser();
  const { t } = useTranslation('offers');
  const location = useLocation();

  return (
    <div className="mx-auto w-full max-w-7xl px-8 pb-12 pt-6 md:pt-6">
      {isLoading && (
        <>
          <div className="border-b border-gray-200 pb-3">
            <div className="mt-auto h-5 w-1/2 animate-pulse rounded bg-gray-200" />
          </div>
          <div className="mt-4 grid grid-cols-1 pt-3">
            {[...Array(user.settings.itemsPerPage).keys()].map((i) => (
              <OfferCardSkeleton key={i} />
            ))}
          </div>
        </>
      )}

      {data && (
        <>
          <div className="border-b border-gray-200 pb-3">
            <PaginationItemsSummary
              page={page}
              recordsPerPage={user.settings.itemsPerPage}
              totalRecords={data.total_items}
            />
          </div>
          <div className="grid grid-cols-1 pt-3">
            {data.results.length === 0 ? (
              <div className="mt-12 text-center">
                <BuildingOfficeIcon className="mx-auto h-10 w-10 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  {t('offers:offerList.noOffers.title')}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t('offers:offerList.noOffers.description')}
                </p>
              </div>
            ) : (
              data.results.map((result) => (
                <OfferCard
                  {...(result.offer as any)}
                  activity={result.most_relevant_activity}
                  offerStatus={result.status}
                  key={(result.offer as any).id}
                  offerType={(result.offer as any).offer_type}
                  isSaved={result.is_saved}
                  assignment={result.assignment}
                  activityCount={
                    (result.comments_count ?? 0) +
                    (result.phone_call_summary_count ?? 0) +
                    (result.sms_messages_count ?? 0)
                  }
                  duplicatesCount={result.duplicates_count}
                  spamProbability={result.spam_probability as number | null}
                  avm={result.avm as AVMResult | null}
                />
              ))
            )}
          </div>
          {data.results.length > 0 && (
            <Pagination
              totalPages={data.total_pages}
              basePath={location.pathname + location.search}
              page={data.page}
              totalRecords={data.total_items}
              recordsPerPage={user.settings.itemsPerPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(OfferList);
