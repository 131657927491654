import * as Yup from 'yup';
import { FilterCondition } from '../../models/filter';
import * as yup from 'yup';
import { format, isValid, parse } from 'date-fns';
import { isDefined } from '../../utils/isDefined';

export const createNonEmptyArray = ({
  lengthError,
  values,
  requiredError = '',
}: {
  lengthError: string;
  values?: any[];
  requiredError?: string;
}) =>
  yup
    .array()
    .min(1, lengthError)
    .of(
      values !== undefined ? yup.mixed().oneOf(values) : yup.mixed().nullable(),
    )
    .required(requiredError);

export const createNumberRangeObject = ({
  typeErrorFrom,
  typeErrorTo,
  rangeError,
}: {
  typeErrorFrom: string;
  typeErrorTo: string;
  rangeError: string;
}) => {
  return Yup.lazy((values) => {
    const {
      [FilterCondition.GREATER_OR_EQUAL_THAN]: fromValue = null,
      [FilterCondition.LESSER_OR_EQUAL_THAN]: toValue = null,
    } = values ?? {};
    let from = Yup.number()
      .transform((v, o) => (o === '' ? null : Number(v)))
      .typeError(typeErrorFrom)
      .nullable(true);
    let to = Yup.number()
      .transform((v, o) => (o === '' ? null : Number(v)))
      .typeError(typeErrorTo)
      .nullable(true);
    if (!!fromValue) {
      to = to.min(fromValue, rangeError);
    }
    if (!!toValue) {
      from = from.max(toValue, rangeError);
    }
    return Yup.object({
      [FilterCondition.GREATER_OR_EQUAL_THAN]: from,
      [FilterCondition.LESSER_OR_EQUAL_THAN]: to,
    });
  });
};

export const createNumberRangeObjects = ({
  typeErrorFrom,
  typeErrorTo,
  rangeError,
  keyFrom,
  keyTo,
}: {
  typeErrorFrom: string;
  typeErrorTo: string;
  rangeError: string;
  keyFrom: string;
  keyTo: string;
}) => {
  return Yup.object({
    [keyFrom]: Yup.object({
      [FilterCondition.GREATER_OR_EQUAL_THAN]: Yup.number()
        .transform((v, o) => (o === '' ? null : Number(v)))
        .typeError(typeErrorFrom)
        .nullable(true),
    }),
    [keyTo]: Yup.object({
      [FilterCondition.LESSER_OR_EQUAL_THAN]: Yup.number()
        .transform((v, o) => (o === '' ? null : Number(v)))
        .typeError(typeErrorTo)
        .nullable(true),
    }),
  })
    .test(
      `lower-${keyFrom}-${keyTo}-range`,
      'Upper bound must be greater than or equal to lower bound',
      function (value) {
        const lowerBound: number | undefined | null = (value as any)[keyFrom][
          FilterCondition.GREATER_OR_EQUAL_THAN
        ];
        const upperBound: number | undefined | null = (value as any)[keyTo][
          FilterCondition.LESSER_OR_EQUAL_THAN
        ];
        if (!isDefined(lowerBound) || !isDefined(upperBound)) {
          return true;
        }
        if (upperBound < lowerBound) {
          return this.createError({
            path: `${keyFrom}.${FilterCondition.GREATER_OR_EQUAL_THAN}`,
            message: rangeError,
          });
        }
        return true;
      },
    )
    .test(
      `upper-${keyFrom}-${keyTo}-range`,
      'Lower bound must be lower than or equal to upper bound',
      function (value) {
        const lowerBound: number | undefined | null = (value as any)[keyFrom][
          FilterCondition.GREATER_OR_EQUAL_THAN
        ];
        const upperBound: number | undefined | null = (value as any)[keyTo][
          FilterCondition.LESSER_OR_EQUAL_THAN
        ];
        if (!isDefined(lowerBound) || !isDefined(upperBound)) {
          return true;
        }
        if (upperBound < lowerBound) {
          return this.createError({
            path: `${keyTo}.${FilterCondition.LESSER_OR_EQUAL_THAN}`,
            message: rangeError,
          });
        }
        return true;
      },
    );
};

export const createDateRangeObject = ({
  typeErrorFrom,
  typeErrorTo,
  rangeError,
  dateFormat,
}: {
  typeErrorFrom: string;
  typeErrorTo: string;
  rangeError: string;
  dateFormat: string;
}) => {
  return Yup.lazy((values) => {
    const {
      [FilterCondition.GREATER_OR_EQUAL_THAN]: fromValue = null,
      [FilterCondition.LESSER_OR_EQUAL_THAN]: toValue = null,
    } = values ?? {};
    const fromValueParsed =
      typeof fromValue === 'string'
        ? parse(fromValue, dateFormat, new Date())
        : fromValue;

    const toValueParsed =
      typeof toValue === 'string'
        ? parse(toValue, dateFormat, new Date())
        : toValue;

    let from = Yup.date()
      .transform((v, o) => (o === '' ? null : parse(o, dateFormat, new Date())))
      .typeError(typeErrorFrom)
      .nullable(true);
    let to = Yup.date()
      .transform((v, o) => {
        if (o === '') {
          return null;
        }
        const val = parse(o, dateFormat, new Date());
        val.setHours(23);
        val.setMinutes(59);
        val.setSeconds(59);
        return val;
      })
      .typeError(typeErrorTo)
      .nullable(true);
    if (!!fromValue && isValid(fromValueParsed)) {
      to = to.min(format(fromValueParsed, dateFormat), rangeError);
    }
    if (!!toValue && isValid(toValueParsed)) {
      from = from.max(format(toValueParsed, dateFormat), rangeError);
    }
    return Yup.object({
      [FilterCondition.GREATER_OR_EQUAL_THAN]: from,
      [FilterCondition.LESSER_OR_EQUAL_THAN]: to,
    });
  });
};

export const createPhoneNumberObject = (typeError: string) => {
  return Yup.object({
    [FilterCondition.EQUAL]: Yup.string()
      .transform((v, o) => (o === '' ? null : v.replaceAll(' ', '')))
      .matches(/^\d{9}$/, typeError)
      .notRequired()
      .nullable(true),
  });
};
