import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TiInfoLarge } from 'react-icons/all';

export interface ExplanationIconProps {
  text: string;
  classNameWrapper?: string;
  classNameIcon?: string;
  size?: 'lg' | 'md';
}

const SIZE_TO_CLASSES = {
  lg: 'h-4 w-4',
  md: 'h-3 w-3',
};

const ExplanationIcon = ({
  text,
  classNameWrapper = '',
  classNameIcon = '',
  size = 'md',
}: ExplanationIconProps) => {
  const id = React.useId();
  return (
    <>
      <div
        data-for={id}
        data-tip={text}
        className={`rounded-full bg-blue-50 p-0.5 ring-1 ring-blue-700 ${classNameWrapper}`}
      >
        <TiInfoLarge
          className={`text-blue-700 ${SIZE_TO_CLASSES[size]} ${classNameIcon}`}
        />
      </div>
      <ReactTooltip id={id} multiline />
    </>
  );
};

export default ExplanationIcon;
