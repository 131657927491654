import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface Props {
  isOpen: boolean;
  title: string;
  description: string;
  children: React.ReactNode;
  close: () => void;
  buttons: React.ReactNode;
}

export const DRAWER_WITH_DESCRIPTION_ANIMATION_DURATION = 500;

const DrawerWithDescription = ({
  isOpen,
  close,
  title,
  description,
  children,
  buttons,
}: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={close}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter={`transform transition ease-in-out duration-${DRAWER_WITH_DESCRIPTION_ANIMATION_DURATION} sm:duration-700`}
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave={`transform transition ease-in-out duration-${DRAWER_WITH_DESCRIPTION_ANIMATION_DURATION} sm:duration-700`}
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={close}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">{description}</p>
                      </div>
                    </div>
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          {children}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      {buttons}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DrawerWithDescription;
