import {
  AddSmsOutreachCampaignRequest,
  SendSmsRequest,
  SmsLogResponse,
  SmsOutreachCampaignListResponse,
  SmsOutreachCampaignResponse,
  SmsSummaryResponse,
  SmsType,
  SmsWithResponsesAndOffersFeed,
  UpdateSmsOutreachCampaignRequest,
} from '../api.types';
import { axios } from '../axios';

export const getSmsCampaigns = ({
  page,
  size,
}: {
  page: number;
  size: number;
}): Promise<SmsOutreachCampaignListResponse> => {
  return axios
    .get<SmsOutreachCampaignListResponse>(`/sms-campaigns`, {
      params: { page, size },
    })
    .then((resp) => resp.data);
};

export const getSmsCampaignById = (
  smsCampaignId: string,
): Promise<SmsOutreachCampaignResponse> => {
  return axios
    .get<SmsOutreachCampaignResponse>(`/sms-campaigns/${smsCampaignId}`)
    .then((resp) => resp.data);
};

export const postSmsCampaign = ({
  body,
}: {
  body: AddSmsOutreachCampaignRequest;
}): Promise<void> => {
  return axios.post(`/sms-campaigns`, body);
};

export const putSmsCampaign = ({
  body,
  smsCampaignId,
}: {
  body: UpdateSmsOutreachCampaignRequest;
  smsCampaignId: string;
}): Promise<void> => {
  return axios.put(`/sms-campaigns/${smsCampaignId}`, body);
};

export const deleteSmsCampaign = (smsCampaignId: string): Promise<void> => {
  return axios.delete(`/sms-campaigns/${smsCampaignId}`);
};

export const getLatestIncomingSms = (): Promise<SmsLogResponse[]> => {
  return axios
    .get<SmsLogResponse[]>(`/me/incoming-sms/latest`)
    .then((resp) => resp.data);
};

export const getSms = ({
  offerId,
  smsCampaignId,
  receiverPhoneNumber,
  smsType,
}: {
  offerId?: string;
  smsCampaignId?: string | null;
  receiverPhoneNumber?: string | null;
  smsType?: SmsType | null;
} = {}): Promise<SmsLogResponse[]> => {
  return axios
    .get<SmsLogResponse[]>(`/sms`, {
      params: {
        offer_id: offerId,
        sms_campaign_id: smsCampaignId,
        receiver_phone_number: receiverPhoneNumber,
        sms_type: smsType,
      },
    })
    .then((resp) => resp.data);
};

export const getSmsResponsesFeed = ({
  page,
  size,
  showOnlyUserSms,
}: {
  page: number;
  size: number;
  showOnlyUserSms?: boolean;
}): Promise<SmsWithResponsesAndOffersFeed> => {
  return axios
    .get<SmsWithResponsesAndOffersFeed>(`/sms/feed`, {
      params: {
        page,
        size,
        response_receiver: showOnlyUserSms ? 'USER' : 'ORGANIZATION',
      },
    })
    .then((resp) => resp.data);
};

export const getSmsSummary = ({
  campaignId,
}: {
  campaignId?: string;
} = {}): Promise<SmsSummaryResponse[]> => {
  return axios
    .get(`/sms/summary`, {
      params: { campaign_id: campaignId },
    })
    .then((resp) => resp.data);
};

export const postActivateSmsCampaign = (
  smsCampaignId: string,
): Promise<void> => {
  return axios.post(`/sms-campaigns/${smsCampaignId}/activate`);
};

export const postDeactivateSmsCampaign = (
  smsCampaignId: string,
): Promise<void> => {
  return axios.post(`/sms-campaigns/${smsCampaignId}/deactivate`);
};

export const postSendSms = ({
  body,
}: {
  body: SendSmsRequest;
}): Promise<void> => {
  return axios.post(`/sms/send`, body);
};
