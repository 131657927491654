import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteSavedOffer, postSaveOffer } from '../../api/offers/offers';
import { OfferTypeEnum } from '../../api/api.types';
import { HeartIcon as HeartIconFull } from '@heroicons/react/24/solid';
import { HeartIcon as HeartIconPartial } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useOptimisticUpdateManager } from '../../context/offer-optimistic-update/use-optimistic-update-manager';
import { useOptimisticUpdates } from '../../context/offer-optimistic-update/use-optimistic-updates';
import { AxiosError } from 'axios';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { useOffersInvalidation } from '../../hooks/use-invalidation/use-offers-invalidation';

interface Props {
  id: string;
  offerType: OfferTypeEnum;
  isSaved: boolean;
  size: 'sm' | 'md' | 'lg';
}

const SIZES = { sm: 'w-6 h-6', md: 'w-8 h-8', lg: 'w-10 h-10' };

const OfferLikeIcon = ({ id, offerType, isSaved, size }: Props) => {
  const { optimisticLikeForOffer, isOptimisticLikeForOffer } =
    useOptimisticUpdates();
  const { setLikedStatus } = useOptimisticUpdateManager();
  const invalidateOffers = useOffersInvalidation();
  const client = useQueryClient();
  const { t } = useTranslation('common');
  const analytics = useAnalytics();
  const { mutate: saveOffer } = useMutation(
    () => postSaveOffer({ offerId: id, offerType }),
    {
      onMutate: async (data) => {
        await client.cancelQueries(['offers']);
        await client.cancelQueries(['savedOffer', offerType, id]);
        return data;
      },
      onSuccess: async () => {
        setLikedStatus(id, true);
        await invalidateOffers();
        await client.invalidateQueries(['savedOffer', offerType, id]);
        await analytics.track(ANALYTICS_EVENTS.OFFER_SAVED, {
          offerType,
          offerId: id,
        });
      },
      onError: async (e: AxiosError) => {
        if (e.response?.status === 422) {
          setLikedStatus(id, true);
          await invalidateOffers();
          await client.invalidateQueries(['savedOffer', offerType, id]);
          return;
        }
        defaultErrorToasts(e, t);
      },
    },
  );
  const { mutate: deleteSaved } = useMutation(
    () => deleteSavedOffer({ offerId: id, offerType: offerType }),
    {
      onMutate: async (data) => {
        await client.cancelQueries(['offers']);
        await client.cancelQueries(['savedOffer', offerType, id]);
        return data;
      },
      onSuccess: async () => {
        setLikedStatus(id, false);
        await invalidateOffers();
        await client.invalidateQueries(['savedOffer', offerType, id]);
        await analytics.track(ANALYTICS_EVENTS.OFFER_UNSAVED, {
          offerType,
          offerId: id,
        });
      },
      onError: async (e: AxiosError) => {
        if (e.response?.status === 404) {
          setLikedStatus(id, false);
          await invalidateOffers();
          await client.invalidateQueries(['savedOffer', offerType, id]);
          return;
        }
        defaultErrorToasts(e, t);
      },
    },
  );

  return (!isOptimisticLikeForOffer(id) && isSaved) ||
    optimisticLikeForOffer(id) ? (
    <HeartIconFull
      className={`${SIZES[size]} text-red-600`}
      onClick={() => deleteSaved()}
    />
  ) : (
    <HeartIconPartial
      className={`${SIZES[size]} text-gray-400`}
      onClick={() => saveOffer()}
    />
  );
};

export default OfferLikeIcon;
