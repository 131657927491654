import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import SelectInput, { SelectInputProps } from './select-input';
import { Listbox } from '@headlessui/react';

export interface SelectInputLabeledArrowProps extends SelectInputProps {
  showLabel?: boolean;
  label?: string;
  placeholder?: string;
  labelClassName?: string;
}

const SelectInputLabeledArrow = ({
  id,
  label,
  placeholder,
  showLabel = false,
  labelClassName = '',
  isError,
  ...props
}: SelectInputLabeledArrowProps) => {
  if (showLabel) {
    props.labelComponent = (
      <Listbox.Label
        htmlFor={id}
        className={`block text-sm font-medium text-gray-700 ${labelClassName}`}
      >
        {label}
      </Listbox.Label>
    );
  }

  const arrow = (isOpen: boolean) => (
    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
      {isOpen && (
        <ChevronUpIcon
          className={`h-5 w-5 ${isError ? 'text-red-300' : 'text-gray-400'}`}
          aria-hidden="true"
        />
      )}
      {!isOpen && (
        <ChevronDownIcon
          className={`h-5 w-5 ${isError ? 'text-red-300' : 'text-gray-400'}`}
          aria-hidden="true"
        />
      )}
    </span>
  );

  return (
    <>
      {label && !showLabel && (
        <label htmlFor={id} className="sr-only">
          {label}
        </label>
      )}
      <SelectInput
        id={id}
        isError={isError}
        {...props}
        suffix={arrow}
        emptyInput={(isOpen) => (
          <>
            {placeholder ? (
              <p className={`${isError ? 'text-red-300' : 'text-gray-500'}`}>
                {placeholder}
              </p>
            ) : (
              <>&nbsp;</>
            )}
            {arrow(isOpen)}
          </>
        )}
      />
    </>
  );
};

export default SelectInputLabeledArrow;
