import { getArrayDepth } from '../constants/array';
import { polygon, union, intersect } from '@turf/turf';

export const flattenBounds = (
  bounds: [number, number][] | [number, number][][],
): [number, number][] => {
  if (getArrayDepth(bounds) === 2) return bounds as [number, number][];

  const flattenedBounds = (bounds as [number, number][][]).reduce(
    (acc, curr) => {
      return [...acc, ...curr];
    },
    [],
  );

  return flattenedBounds;
};

export const mergeBounds = (
  prevBounds: [number, number][][] | null,
  newBounds: [number, number][],
): [number, number][][] => {
  if (!prevBounds) {
    return [newBounds];
  }

  return mergeOverlappingPolygons([...prevBounds, newBounds]);
};

export const mergeOverlappingPolygons = (
  polygons: [number, number][][],
): [number, number][][] => {
  const result: [number, number][][] = [];

  // Convert arrays to turf polygons
  const turfPolygons = polygons.map((coords) =>
    polygon([coords.map(([lat, lng]) => [lng, lat])]),
  );

  // Merge overlapping polygons
  for (let i = 0; i < turfPolygons.length; i++) {
    for (let j = i + 1; j < turfPolygons.length; j++) {
      if (intersect(turfPolygons[i], turfPolygons[j])) {
        turfPolygons[i] = union(turfPolygons[i], turfPolygons[j]) as any;
        turfPolygons.splice(j, 1);
        j--; // Adjust index after removing an element
      }
    }

    result.push((turfPolygons[i].geometry.coordinates as any)[0]);
  }
  console.log(result);
  return result.map((row) => row.map(([lng, lat]) => [lat, lng]));
};

export const ensure3dBounds = (
  bounds: [number, number][] | [number, number][][] | null,
): [number, number][][] => {
  if (!bounds) return [];
  if (getArrayDepth(bounds) === 2) {
    return [bounds as [number, number][]];
  }
  return bounds as [number, number][][];
};
