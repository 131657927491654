import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useUser } from '../auth/use-user';
import { OfferTypeEnum } from '../../api/api.types';
import { SEARCH_FROM_FIELDS } from './offers-search-form';
import React from 'react';
import {
  createDateRangeObject,
  createNonEmptyArray,
  createNumberRangeObject,
  createNumberRangeObjects,
  createPhoneNumberObject,
} from './offers-search-form-validation-helpers';

export const useOffersSearchFormValidation = () => {
  const user = useUser();
  const { t } = useTranslation(['forms', 'validation']);

  return React.useMemo(() => {
    const floorNumberSchema = createNumberRangeObjects({
      typeErrorFrom: t('forms:errors.floor.from'),
      typeErrorTo: t('forms:errors.floor.to'),
      rangeError: t('forms:errors.floor.range'),
      keyFrom: SEARCH_FROM_FIELDS.FLOOR_LOWER_BOUND_KEY,
      keyTo: SEARCH_FROM_FIELDS.FLOOR_UPPER_BOUND_KEY,
    });
    const levelsInBuildingSchema = createNumberRangeObjects({
      typeErrorFrom: t('forms:errors.totalLevelsInBuilding.from'),
      typeErrorTo: t('forms:errors.totalLevelsInBuilding.to'),
      rangeError: t('forms:errors.totalLevelsInBuilding.range'),
      keyFrom: SEARCH_FROM_FIELDS.LEVELS_IN_BUILDING_LOWER_BOUND_KEY,
      keyTo: SEARCH_FROM_FIELDS.LEVELS_IN_BUILDING_UPPER_BOUND_KEY,
    });

    const result = Yup.object({
      offersTypes: createNonEmptyArray({
        lengthError: t('forms:errors.categoriesEmpty'),
        values: Object.values(OfferTypeEnum),
        requiredError: t('validation:required'),
      }),
      price: createNumberRangeObject({
        typeErrorFrom: t('forms:errors.price.from'),
        typeErrorTo: t('forms:errors.price.to'),
        rangeError: t('forms:errors.price.range'),
      }),
      property_size: createNumberRangeObject({
        typeErrorFrom: t('forms:errors.size.from'),
        typeErrorTo: t('forms:errors.size.to'),
        rangeError: t('forms:errors.size.range'),
      }),
      rooms: createNumberRangeObject({
        typeErrorFrom: t('forms:errors.rooms.from'),
        typeErrorTo: t('forms:errors.rooms.to'),
        rangeError: t('forms:errors.rooms.range'),
      }),
      bathroom_count: createNumberRangeObject({
        typeErrorFrom: t('forms:errors.bathrooms.from'),
        typeErrorTo: t('forms:errors.bathrooms.to'),
        rangeError: t('forms:errors.bathrooms.range'),
      }),
      date_scraped: createDateRangeObject({
        typeErrorFrom: t('forms:errors.dateScraped.from'),
        typeErrorTo: t('forms:errors.dateScraped.to'),
        rangeError: t('forms:errors.dateScraped.range'),
        dateFormat: user.settings.dateFormat,
      }),
      date_smart_last_seen: createDateRangeObject({
        typeErrorFrom: t('forms:errors.dateSmartLastSeen.from'),
        typeErrorTo: t('forms:errors.dateSmartLastSeen.to'),
        rangeError: t('forms:errors.dateSmartLastSeen.range'),
        dateFormat: user.settings.dateFormat,
      }),
      contact_number: createPhoneNumberObject(t('forms:errors.phone')),
      levels: createNumberRangeObject({
        typeErrorFrom: t('forms:errors.levels.from'),
        typeErrorTo: t('forms:errors.levels.to'),
        rangeError: t('forms:errors.levels.range'),
      }),
      parking_lots: createNumberRangeObject({
        typeErrorFrom: t('forms:errors.parkingLots.from'),
        typeErrorTo: t('forms:errors.parkingLots.to'),
        rangeError: t('forms:errors.parkingLots.range'),
      }),
      land_length: createNumberRangeObject({
        typeErrorFrom: t('forms:errors.landLength.from'),
        typeErrorTo: t('forms:errors.landLength.to'),
        rangeError: t('forms:errors.landLength.range'),
      }),
      land_width: createNumberRangeObject({
        typeErrorFrom: t('forms:errors.landWidth.from'),
        typeErrorTo: t('forms:errors.landWidth.to'),
        rangeError: t('forms:errors.landWidth.range'),
      }),
      year_built: createNumberRangeObject({
        typeErrorFrom: t('forms:errors.yearBuilt.from'),
        typeErrorTo: t('forms:errors.yearBuilt.to'),
        rangeError: t('forms:errors.yearBuilt.range'),
      }),
    });

    return result.concat(floorNumberSchema).concat(levelsInBuildingSchema);
  }, [user.settings.language]);
};
