import { SiMetrodeparis } from 'react-icons/si';
import { MdOutlinePhotoSizeSelectSmall, MdTram } from 'react-icons/md';
import { BiBus, BiLink, BiRectangle } from 'react-icons/bi';
import { MapIcon, NoSymbolIcon } from '@heroicons/react/20/solid';
import { AiFillFilter } from 'react-icons/ai';
import { BsNewspaper } from 'react-icons/bs';
import { FaInfo } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';
import { IoMdSave } from 'react-icons/io';

export const EXTRA_ICONS = {
  contact_restriction: (props: any) => <NoSymbolIcon {...props} />,
  distance_from_subway: SiMetrodeparis,
  distance_from_tram: MdTram,
  distance_from_bus: BiBus,
  filters: AiFillFilter,
  offer_info: BsNewspaper,
  land: BiRectangle,
  info: FaInfo,
  size: MdOutlinePhotoSizeSelectSmall,
  open: BiLink,
  is_active: IoMdRefresh,
  location: (props: any) => <MapIcon {...props} />,
  save: IoMdSave,
};
