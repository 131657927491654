import React from 'react';
import { Controller } from 'react-hook-form';
import { InputLabelProps } from '../shared/input-label';
import TextAreaInputLabeled from './text-area-input-labeled';

interface Props extends Omit<InputLabelProps, 'labelFor'> {
  name: string;
  className?: string;
  control: any;
  id: string;
  autoComplete?: string;
  rows?: number;
}

const TextAreaInputLabeledController = ({ control, name, ...rest }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextAreaInputLabeled
          onChange={onChange}
          isError={!!error}
          errorMessage={error?.message}
          value={value}
          {...rest}
        />
      )}
    />
  );
};

export default TextAreaInputLabeledController;
