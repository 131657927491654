import React from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Listbox, Transition } from '@headlessui/react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface SelectOption {
  label: string;
  value: string;
  icon?: JSX.Element;
}

export interface SelectInputProps {
  options: SelectOption[];
  onChange: (value: string) => void;
  labelComponent?: JSX.Element;
  value: string | null;
  id?: string;
  className?: string;
  clearable?: boolean;
  buttonClassName?: string;
  emptyInput?: (isOpen: boolean) => React.ReactElement;
  suffix?: (isOpen: boolean) => React.ReactElement;
  errorMessage?: string;
  isError?: boolean;
  saveErrorSpace?: boolean;
  wrapperClassName?: string;
}

const SelectInput = ({
  value = null,
  onChange,
  labelComponent,
  className = '',
  buttonClassName = '',
  id = '',
  options,
  clearable = true,
  emptyInput = () => <>-</>,
  suffix = () => <></>,
  errorMessage = '',
  isError = false,
  saveErrorSpace = false,
  wrapperClassName = '',
}: SelectInputProps) => {
  const val = options.find((o) => o.value === value);

  return (
    <div className={wrapperClassName}>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <div className={className}>
            {labelComponent}
            <div className="relative">
              <Listbox.Button
                id={id}
                className={`relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm ${buttonClassName} ${
                  isError
                    ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
                    : ''
                }`}
              >
                <span className="block flex items-center truncate">
                  {val ? (
                    <>
                      {val.icon} {val.label} {!clearable && suffix(open)}
                    </>
                  ) : (
                    emptyInput(open)
                  )}
                </span>
                {clearable && value && (
                  <span
                    className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange('');
                    }}
                  >
                    <XMarkIcon
                      className="h-5 w-5 cursor-pointer text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                )}
              </Listbox.Button>

              <Transition
                show={open}
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.label}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                        )
                      }
                      value={option.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'flex items-center truncate',
                            )}
                          >
                            {option.icon} {option.label}
                          </span>
                          {selected && (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
      {(isError || saveErrorSpace) && (
        <p
          className={`mt-2 text-xs text-red-600 ${
            saveErrorSpace ? 'whitespace-pre-wrap' : ''
          }`}
        >
          {isError ? errorMessage : ' '}
        </p>
      )}
    </div>
  );
};

export default SelectInput;
