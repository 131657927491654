import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { MeetingFieldKeys, useGetMeetingFields } from '../../forms/meeting';
import { isDefined } from '../../utils/isDefined';
import { isBefore } from 'date-fns';
import {
  ClientFieldKeys,
  ClientFieldTypesRaw,
  ClientFieldTypesValidated,
  ClientType,
  useGetClientFields,
} from '../../forms/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { postAddClient } from '../../api/clients/clients';
import {
  postMeeting,
  postMeetingFairAssignment,
} from '../../api/meetings/meetings-endpoints';
import { useToast } from '../use-toast/use-toast';

interface Props {
  useFairAssignments?: boolean;
  offerId?: string;
  afterSuccessSubmit?: () => void;
  defaults?: Partial<RawCreateMeetingFieldTypes>;
}

export const useMeetingCreateForm = ({
  offerId,
  afterSuccessSubmit,
  defaults,
  useFairAssignments = false,
}: Props) => {
  const schema = useMeetingAddFormValidation();
  const { handleSubmit, ...results } = useForm<RawCreateMeetingFieldTypes>({
    defaultValues: defaults,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const { isLoading, mutate } = useOnSubmit({
    offerId,
    afterSuccessSubmit,
    useFairAssignments,
  });

  return {
    ...results,
    isLoading,
    submit: handleSubmit(mutate),
  };
};

const useOnSubmit = ({
  afterSuccessSubmit,
  offerId,
  useFairAssignments = false,
}: {
  afterSuccessSubmit?: () => void;
  offerId?: string;
  useFairAssignments?: boolean;
}) => {
  const { displayErrorToast, displayDefaultErrorToasts, displaySuccessToast } =
    useToast();
  const { t } = useTranslation(['meetings', 'client-searches']);
  const queryClient = useQueryClient();

  const { mutateAsync: createMeeting, isLoading: isCreateMeetingLoading } =
    useMutation(useFairAssignments ? postMeetingFairAssignment : postMeeting, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['meetings']);
        await queryClient.invalidateQueries(['offer', 'meetings']);
        await queryClient.invalidateQueries([
          'fair-meetings-allowance',
          offerId,
        ]);
        displaySuccessToast({
          title: t('meetings:drawer.create.successToast.title'),
          body: t('meetings:drawer.create.successToast.body'),
        });
      },
      onError: async (e: AxiosError) => {
        displayDefaultErrorToasts(e);
      },
    });

  const { mutateAsync: createClient, isLoading: isCreateClientLoading } =
    useMutation(postAddClient, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clients']);
        await queryClient.invalidateQueries(['contactedClients']);
      },
      onError: async (e: AxiosError) => {
        if (
          e.response?.status === 422 &&
          (e.response?.data as any)?.message?.includes('integrity constraint')
        ) {
          displayErrorToast({
            title: t(
              'client-searches:addOfferToClientSearchDrawer.clientDuplicate.title',
            ),
            body: t(
              'client-searches:addOfferToClientSearchDrawer.clientDuplicate.body',
            ),
          });
        } else {
          displayDefaultErrorToasts(e);
        }
      },
    });

  return {
    isLoading: isCreateMeetingLoading || isCreateClientLoading,
    mutate: async (data: RawCreateMeetingFieldTypes) => {
      const validatedData = data as CreateMeetingFieldTypes;

      let linkedClientId;
      if (data[ClientFieldKeys.CLIENT_TYPE] === ClientType.NEW) {
        const { client_id } = await createClient({
          body: {
            first_name: validatedData[ClientFieldKeys.CLIENT_FIRST_NAME],
            last_name: validatedData[ClientFieldKeys.CLIENT_LAST_NAME],
            phone_number: `+48${
              validatedData[ClientFieldKeys.CLIENT_PHONE_NUMBER]
            }`,
            email: validatedData[ClientFieldKeys.CLIENT_EMAIL] ?? undefined,
          },
        });
        linkedClientId = client_id;
      } else {
        linkedClientId = validatedData[ClientFieldKeys.CLIENT_ID];
      }
      await createMeeting({
        title: validatedData[MeetingFieldKeys.TITLE],
        description: validatedData[MeetingFieldKeys.DESCRIPTION],
        linked_offer_id: offerId,
        linked_client_id: linkedClientId,
        start_time: validatedData[MeetingFieldKeys.START_TIME],
        end_time: validatedData[MeetingFieldKeys.END_TIME],
        assigned_user_id: validatedData[MeetingFieldKeys.ASSIGNED_USER_ID],
      });
      afterSuccessSubmit?.();
    },
  };
};

const useMeetingAddFormValidation = () => {
  const { t } = useTranslation(['meetings']);

  const getMeetingFields = useGetMeetingFields();
  const getClientFields = useGetClientFields();

  return Yup.object({
    ...getClientFields({
      mappings: {
        [ClientFieldKeys.CLIENT_ID]: () => Yup.string().nullable(),
      },
    }),
    ...getMeetingFields({
      exclude: [
        MeetingFieldKeys.RESPONSE_STATUS,
        MeetingFieldKeys.RESPONSE_NOTE,
        MeetingFieldKeys.RESULT_NOTE,
        MeetingFieldKeys.LINKED_CLIENT_ID,
      ],
    }),
  }).test(
    `end_time_after_or_equal_start_time`,
    'End time must be after or equal start time',
    function (value) {
      const startTime: Date | undefined | null = (value as any)[
        MeetingFieldKeys.START_TIME
      ];
      const endTime: Date | undefined | null = (value as any)[
        MeetingFieldKeys.END_TIME
      ];
      if (!isDefined(startTime) || !isDefined(endTime)) {
        return true;
      }
      if (isBefore(endTime, startTime)) {
        return this.createError({
          path: MeetingFieldKeys.END_TIME,
          message: t('meetings:validation.meetingsTimeRange'),
        });
      }
      return true;
    },
  );
};

export interface CreateMeetingFieldTypesBase {
  [MeetingFieldKeys.TITLE]: string;
  [MeetingFieldKeys.DESCRIPTION]: string | null;
  [MeetingFieldKeys.ASSIGNED_USER_ID]: string | null;
}

export interface RawCreateMeetingFieldTypes
  extends Partial<CreateMeetingFieldTypesBase>,
    ClientFieldTypesRaw {
  [MeetingFieldKeys.START_TIME]: Date | null;
  [MeetingFieldKeys.END_TIME]: Date | null;
}

export interface CreateMeetingFieldTypes
  extends CreateMeetingFieldTypesBase,
    ClientFieldTypesValidated {
  [MeetingFieldKeys.START_TIME]: Date;
  [MeetingFieldKeys.END_TIME]: Date;
}
