import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navigation from './components/navigation/navigation';
import { QueryClient } from '@tanstack/react-query';
import { AuthProvider } from './context/auth/auth-provider';
import AuthenticatedRoute from './components/routes/authenticated-route/authenticated-route';
import NotAuthenticatedRoute from './components/not-authenticated-route/not-authenticated-route';
import NoNav from './components/no-nav/no-nav';
import Spinner from './components/spinner/spinner';
import { Toaster } from 'react-hot-toast';
import GlobalErrorHandler from './components/global-error-handler/global-error-handler';
import { OfferOptimisticUpdateProvider } from './context/offer-optimistic-update/optimistic-update-provider';
import AuthRedirect from './components/auth-redirect/auth-redirector';
import Logout from './pages/logout/logout';
import ActivateAccount from './pages/activate-account/activate-account';
import ResetPasswordChange from './pages/reset-password/reset-password';
import AccountCreated from './pages/account-created/account-created';
import OrganizationB2bRoute from './components/routes/organization-b2b-route/organization-b2b-route';
import SignUpToOrganization from './pages/sign-up-to-organization/sign-up-to-organization';
import { ANALYTICS } from './constants/analytics';
import { AnalyticsProvider } from './context/analytics/analytics-provider';
import InitialLoginModals from './components/initial-login-modals/initial-login-modals';
import { withProfiler } from '@sentry/react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { HOURS_24 } from './constants/periods';
import { retryImport } from './utils/retry-import';
import MyOffers from './pages/my-offers/my-offers';
import { LocalStorageProvider } from './context/local-storage/local-storage';
import { AppViewProvider } from './context/app-views/app-view-provider';
import SalesAssistant from './pages/sales-assistant/sales-assistant';
import { SubscriptionTierType } from './models/user';
import DataSets from './pages/data-sets/data-sets';
import { AppView } from './context/app-views/use-app-view';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: HOURS_24,
    },
  },
});

const hash = __COMMIT_HASH__;

// consider adding compression in the future https://tanstack.com/query/v4/docs/react/plugins/createSyncStoragePersister#serialize-and-deserialize-options
const syncStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

const CallList = React.lazy(() =>
  retryImport(() => import('./pages/call-list/call-list')),
);
const Login = React.lazy(() =>
  retryImport(() => import('./pages/login/login')),
);
const SignUp = React.lazy(() =>
  retryImport(() => import('./pages/sign-up/sign-up')),
);
const Views = React.lazy(() =>
  retryImport(() => import('./pages/views/views')),
);
const Dashboard = React.lazy(() =>
  retryImport(() => import('./pages/dashboard/dashboard')),
);
const Settings = React.lazy(() =>
  retryImport(() => import('./pages/settings/settings')),
);
const Organization = React.lazy(() =>
  retryImport(() => import('./pages/organization/organization')),
);
const SavedOffers = React.lazy(() =>
  retryImport(() => import('./pages/saved-offers/saved-offers')),
);
const Offers = React.lazy(() =>
  retryImport(() => import('./pages/offers/offers')),
);
const NotFound = React.lazy(() =>
  retryImport(() => import('./pages/not-found/not-found')),
);
const ForgotPassword = React.lazy(() =>
  retryImport(() => import('./pages/forgot-password/forgot-password')),
);
const Clients = React.lazy(() =>
  retryImport(() => import('./pages/clients/clients')),
);
const SmsCampaigns = React.lazy(() =>
  retryImport(() => import('./pages/sms-campaigns/sms-campaigns')),
);
const ClientSearches = React.lazy(() =>
  retryImport(() => import('./pages/client-searches/client-searches')),
);
const PublicAgencyView = React.lazy(() =>
  retryImport(
    () => import('./public/pages/shared-offer/shared-offer-public-view'),
  ),
);
const PublicAgencyListView = React.lazy(() =>
  retryImport(
    () => import('./public/pages/shared-offers/shared-offers-public-view'),
  ),
);
const MonitoredBooks = React.lazy(() =>
  retryImport(() => import('./pages/monitored-books/monitored-books-router')),
);

const Analytics = React.lazy(() =>
  retryImport(() => import('./pages/analytics/analytics')),
);
const Payment = React.lazy(() =>
  retryImport(() => import('./pages/payment/payment')),
);
const AVM = React.lazy(() => retryImport(() => import('./pages/avm/avm')));
const OfferExport = React.lazy(() =>
  retryImport(() => import('./pages/offer-export/offer-export')),
);
const OauthCallback = React.lazy(() =>
  retryImport(() => import('./pages/oauth-callback/oauth-callback')),
);
const Meetings = React.lazy(() =>
  retryImport(() => import('./pages/meetings/meetings')),
);

function App() {
  return (
    <React.Suspense fallback={<Spinner />}>
      <AnalyticsProvider instance={ANALYTICS}>
        <LocalStorageProvider>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister: syncStoragePersister,
              buster: hash,
              maxAge: HOURS_24,
            }}
          >
            <GlobalErrorHandler>
              <OfferOptimisticUpdateProvider>
                <BrowserRouter>
                  <AuthProvider>
                    <AppViewProvider>
                      <Toaster
                        position="top-right"
                        toastOptions={{ duration: 2000 }}
                      />
                      <Routes>
                        <Route
                          path="/activate/:activateId"
                          element={<ActivateAccount />}
                        />
                        <Route
                          path="/reset-password/:resetPasswordId"
                          element={<ResetPasswordChange />}
                        />
                        <Route
                          path="/sign-up/organization-invite/:inviteId"
                          element={
                            <NotAuthenticatedRoute>
                              <NoNav>
                                <SignUpToOrganization />
                              </NoNav>
                            </NotAuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/sign-up"
                          element={
                            <NotAuthenticatedRoute>
                              <NoNav>
                                <SignUp />
                              </NoNav>
                            </NotAuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/login"
                          element={
                            <NotAuthenticatedRoute>
                              <NoNav>
                                <Login />
                              </NoNav>
                            </NotAuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/forgot-password"
                          element={
                            <NotAuthenticatedRoute>
                              <ForgotPassword />
                            </NotAuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/logout"
                          element={
                            <NotAuthenticatedRoute>
                              <NoNav>
                                <Logout />
                              </NoNav>
                            </NotAuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/account-created"
                          element={
                            <NotAuthenticatedRoute>
                              <NoNav>
                                <AccountCreated />
                              </NoNav>
                            </NotAuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/views"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.BASIC
                              }
                            >
                              <InitialLoginModals>
                                <Navigation>
                                  <Views />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/sales-assistant/*"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.ENTERPRISE
                              }
                            >
                              <InitialLoginModals>
                                <Navigation>
                                  <SalesAssistant />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/call-list/*"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.BASIC
                              }
                            >
                              <InitialLoginModals>
                                <Navigation>
                                  <CallList />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/offers/*"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.BASIC
                              }
                            >
                              <InitialLoginModals>
                                <Navigation contentWrapperClassName="max-w-full">
                                  <Offers />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/my-offers/*"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.BASIC
                              }
                            >
                              <InitialLoginModals>
                                <Navigation>
                                  <MyOffers />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/offer-export/*"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.PREMIUM
                              }
                            >
                              <InitialLoginModals>
                                <Navigation contentWrapperClassName="max-w-full">
                                  <OfferExport />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/saved-offers"
                          element={
                            <AuthenticatedRoute>
                              <InitialLoginModals>
                                <Navigation>
                                  <SavedOffers />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/avm/*"
                          element={
                            <AuthenticatedRoute>
                              <InitialLoginModals>
                                <Navigation>
                                  <AVM />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/meetings/*"
                          element={
                            <AuthenticatedRoute>
                              <InitialLoginModals>
                                <Navigation contentWrapperClassName="!mx-0 !max-w-none">
                                  <Meetings />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/settings/*"
                          element={
                            <AuthenticatedRoute>
                              <InitialLoginModals>
                                <Navigation>
                                  <Settings />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/clients/*"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.BASIC
                              }
                            >
                              <InitialLoginModals>
                                <Navigation contentWrapperClassName="!mx-0 !max-w-none">
                                  <Clients />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/organization/*"
                          element={
                            <OrganizationB2bRoute>
                              <InitialLoginModals>
                                <Navigation>
                                  <Organization />
                                </Navigation>
                              </InitialLoginModals>
                            </OrganizationB2bRoute>
                          }
                        />
                        <Route
                          path="/dashboard"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.BASIC
                              }
                            >
                              <InitialLoginModals>
                                <Navigation bgClassName="bg-gray-100">
                                  <Dashboard />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/sms-campaigns/*"
                          element={
                            <AuthenticatedRoute>
                              <InitialLoginModals>
                                <Navigation>
                                  <SmsCampaigns />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/payments/*"
                          element={
                            <AuthenticatedRoute>
                              <InitialLoginModals>
                                <Navigation>
                                  <Payment />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/client-searches/*"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.BASIC
                              }
                            >
                              <InitialLoginModals>
                                <Navigation contentWrapperClassName="!max-w-full !mx-0">
                                  <ClientSearches />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/monitored-books/*"
                          element={
                            <AuthenticatedRoute
                              requiredSubscriptionTier={
                                SubscriptionTierType.PREMIUM
                              }
                            >
                              <InitialLoginModals>
                                <Navigation contentWrapperClassName="!mx-0 !max-w-none">
                                  <MonitoredBooks />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/public/list/:clientSearchId"
                          element={<PublicAgencyListView />}
                        />
                        <Route
                          path="/public/:offerVariantId"
                          element={<PublicAgencyView />}
                        />
                        <Route
                          path="/"
                          element={
                            <AuthRedirect
                              toWhenAuth={'/dashboard'}
                              toWhenNotAuth={'/login'}
                            />
                          }
                        />
                        <Route
                          path="/analytics"
                          element={
                            <AuthenticatedRoute
                              requiredAppView={AppView.ANALYTICS_APP}
                            >
                              <InitialLoginModals>
                                <Navigation contentWrapperClassName="!mx-0 !max-w-none">
                                  <Analytics />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/oauth-callback/*"
                          element={
                            <AuthenticatedRoute>
                              <OauthCallback />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/data-sets"
                          element={
                            <AuthenticatedRoute
                              requiredAppView={AppView.ANALYTICS_APP}
                            >
                              <InitialLoginModals>
                                <Navigation contentWrapperClassName="!mx-0 !max-w-none">
                                  <DataSets />
                                </Navigation>
                              </InitialLoginModals>
                            </AuthenticatedRoute>
                          }
                        />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </AppViewProvider>
                  </AuthProvider>
                </BrowserRouter>
              </OfferOptimisticUpdateProvider>
            </GlobalErrorHandler>
          </PersistQueryClientProvider>
        </LocalStorageProvider>
      </AnalyticsProvider>
    </React.Suspense>
  );
}

export default withProfiler(App);
