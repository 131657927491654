import { IssuerTypeEnum } from '../../api/api.types';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { BriefcaseIcon, KeyIcon, TruckIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { isDefined } from '../../utils/isDefined';

const ISSUER_TYPE_BG: { [key in IssuerTypeEnum]: string } = {
  PRIVATE: 'bg-cyan-500',
  REAL_ESTATE_AGENCY: 'bg-emerald-500',
  DEVELOPER: 'bg-lime-500',
};

const ISSUER_TYPE_ICONS: {
  [key in IssuerTypeEnum]: (
    props: React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>,
  ) => React.ReactElement;
} = {
  PRIVATE: (props) => <KeyIcon {...props} />,
  REAL_ESTATE_AGENCY: (props) => <BriefcaseIcon {...props} />,
  DEVELOPER: (props) => <TruckIcon {...props} />,
};

interface Props {
  issuerType: IssuerTypeEnum;
  className?: string;
  size: 'sm' | 'md' | 'lg';
  showTooltip?: boolean;
}

const SIZE_CLASSES = {
  sm: 'h-3 w-3',
  md: 'h-4 w-4',
  lg: 'h-6 w-6',
};

const IssuerTypeIcon = ({
  issuerType,
  className = '',
  size,
  showTooltip = true,
}: Props) => {
  const { t } = useTranslation('data-model');

  if (!isDefined(ISSUER_TYPE_ICONS[issuerType])) {
    return <></>;
  }

  return (
    <>
      <div
        data-effect="solid"
        data-for="issuerType"
        data-tip={t(`data-model:enumLabels.issuer_type.${issuerType}`)}
        className={`rounded-full text-white ${ISSUER_TYPE_BG[issuerType]} ${
          size === 'lg' ? 'p-1.5' : 'p-1'
        } flex items-center justify-center ${className}`}
      >
        {ISSUER_TYPE_ICONS[issuerType]({ className: SIZE_CLASSES[size] })}
      </div>
      {showTooltip && <ReactTooltip id="issuerType" />}
    </>
  );
};

export default IssuerTypeIcon;
