import React from 'react';
import OfferCardMenu from './offer-card-menu';
import {
  AVMResult,
  FeatureFlag,
  IssuerTypeEnum,
  LandShapeEnum,
  LandTypeEnum,
  MostRelevantActivity,
  OfferStatus,
  OfferStatusResponse,
  OfferTypeEnum,
  PhoneCallResultEnum,
} from '../../api/api.types';
import { useTranslation } from 'react-i18next';
import CurrencyFormatter from '../formatters/currency-formatter';
import SizeFormatter, { getValueAndUnit } from '../formatters/size-formatter';
import CurrencyPerSizeFormatter from '../formatters/currency-per-size-formatter';
import { generateOfferLink } from '../../utils/generate-offer-link';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postOfferStatus } from '../../api/offers/offers';
import { useOptimisticUpdateManager } from '../../context/offer-optimistic-update/use-optimistic-update-manager';
import OfferTypeIcon from '../icons/offer-type-icon';
import { Link } from 'react-router-dom';
import { formatAddress } from '../../models/address';
import { isSpam } from '../../models/spam';
import ContactNumberButton from '../contact-number-button/contact-number-button';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import IssuerTypeIcon from '../icons/issuer-type-icon';
import OfferImagesSlider from '../slider/offer-images-slider';
import ActivityIcon from '../activity/activity-icon';
import OfferLastSeenBadge from './offer-badges/offer-last-seen-badge';
import OfferIndexBadge from './offer-badges/offer-index-badge';
import OfferDuplicatesPopover from './offer-popovers/offer-duplicates-popover';
import OfferDateScrapedBadge from './offer-badges/offer-date-scraped-badge';
import OfferSpamIndicator from './offer-indicators/offer-spam-indicator';
import OfferContactRestrictionIndicator from './offer-indicators/offer-contact-restriction-indicator';
import ActivityIndicator from '../activity/activity-indicator';
import { PhoneIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import OfferLikeIcon from './offer-save-icon';
import AddToClientSearchDrawer from '../client-offer-search/add-to-client-search-drawer';
import { useOffersInvalidation } from '../../hooks/use-invalidation/use-offers-invalidation';
import { OfferAssignmentResponse } from '../../api/offer-assignments/offer-assignments';
import { useAssignOffer } from '../../hooks/use-assign-offer/use-assign-offer';
import { useSm } from '../../style/media-queries';
import { useUser } from '../../context/auth/use-user';
import OfferAssignmentBadge from './offer-badges/offer-assignment-badge';
import { addDays, parseISO } from 'date-fns';
import { useOfferNavigate } from '../../hooks/use-navigate/use-offer-navigate';
import { SubscriptionTierType } from '../../models/user';
import MeetingCreateDrawer from '../meetings/drawers/meeting-create-drawer';
import OfferPriceChangeBadge from './offer-badges/offer-price-change-badge';

interface Props {
  id: string;
  property_size?: number | null;
  title: string;
  price: number;
  photos_urls: string[];
  downloaded_photos_urls?: string[];

  address_city: string;
  address_street_number?: string | null;
  address_street?: string | null;
  address_district?: string | null;
  address_state: string;
  address_county: string;
  address_community: string;

  bathroom_count?: number | null;
  rooms?: number | null;
  year_built?: number | null;
  floor_no_upper_bound?: number | null;
  floor_no_lower_bound?: number | null;

  issuer_type?: IssuerTypeEnum | null | IssuerTypeEnum[];
  description_clean_text?: string | null;
  tag: string | string[];
  contact_number?: string | null | string[];
  offer_original_url: string;
  price_currency?: string | null;
  date_scraped: string;
  offerStatus?: OfferStatusResponse;
  land_type?: LandTypeEnum | null | LandTypeEnum[];
  land_shape?: LandShapeEnum | null | LandShapeEnum[];

  offerType: OfferTypeEnum;
  isSaved: boolean;
  activityCount: number;
  duplicatesCount: number;
  spamProbability: number | null;
  avm: AVMResult | null;
  price_per_meter?: number | null;
  contact_restriction?: boolean;
  activity?: MostRelevantActivity;
  is_active?: boolean;
  date_smart_last_seen?: string;
  date_last_seen: string;
  assignment?: OfferAssignmentResponse;
  previous_price?: number | null;
}

const OfferCard = ({
  offer_original_url,
  id,
  photos_urls,
  title,
  price,
  address_street,
  address_street_number,
  address_district,
  address_city,
  address_state,
  address_county,
  address_community,
  bathroom_count,
  rooms,
  property_size,
  year_built,
  floor_no_lower_bound,
  floor_no_upper_bound,
  description_clean_text,
  issuer_type,
  tag,
  contact_number,
  offerType,
  offerStatus,
  price_currency,
  date_scraped,
  land_type,
  land_shape,
  isSaved,
  activityCount,
  duplicatesCount,
  spamProbability,
  avm,
  price_per_meter,
  downloaded_photos_urls,
  contact_restriction,
  date_smart_last_seen,
  date_last_seen,
  activity,
  is_active,
  assignment,
  previous_price,
}: Props) => {
  const isSm = useSm();
  const analytics = useAnalytics();
  const navigateToOffer = useOfferNavigate({ offerId: id, offerType });
  const [isAddToClientSearchPanelOpen, setIsAddToClientSearchPanelOpen] =
    React.useState(false);
  const [isCreateMeetingPanelOpen, setIsCreateMeetingPanelOpen] =
    React.useState(false);
  const { t } = useTranslation(['data-model', 'common']);
  const { setOfferStatus } = useOptimisticUpdateManager();
  const queryClient = useQueryClient();
  const invalidateOffers = useOffersInvalidation();
  const { mutate: addStatus } = useMutation(postOfferStatus, {
    onMutate: async (data) => {
      await queryClient.cancelQueries(['offers']);
      await queryClient.cancelQueries(['offerStatuses', offerType, id]);
      return data;
    },
    onSuccess: async (_, { body }) => {
      setOfferStatus(id, body.status, false);
      await invalidateOffers();
      await queryClient.invalidateQueries(['offerStatuses', offerType, id]);
      await analytics.track(ANALYTICS_EVENTS.OFFER_STATUS_ADDED, {
        offerType,
        offerId: id,
      });
    },
  });
  const markForContact = () => {
    addStatus({
      offerType,
      offerId: id,
      body: { status: OfferStatus.MARKED_FOR_CONTACT },
    });
  };
  const user = useUser();
  const spam = isSpam(spamProbability);
  const { assignOffer } = useAssignOffer();

  const showTimer =
    assignment &&
    assignment.valid_until &&
    addDays(parseISO(assignment.valid_until), 1) > new Date() &&
    ((activity?.call_result === null && activity?.offer_status === null) ||
      (activity?.call_result?.call_result &&
        PhoneCallResultEnum.CLIENT_NOT_INTERESTED ===
          activity.call_result.call_result));

  const canUserUseMeetings =
    user.hasFeatureFlag(FeatureFlag.MEETINGS_ENABLED) &&
    user.hasSufficientValidSubscriptionTier(SubscriptionTierType.PREMIUM);

  return (
    <div className="relative my-2 flex flex-col rounded-xl border bg-white md:flex-row">
      <div className="relative overflow-hidden rounded-t-xl pb-64 pr-36 md:rounded-l-xl md:rounded-r-none md:pb-48 md:pr-64">
        <OfferImagesSlider
          images={downloaded_photos_urls ?? photos_urls}
          isOriginal={!!downloaded_photos_urls}
        />
        <OfferLastSeenBadge
          smartDateLastSeen={date_smart_last_seen ?? date_last_seen}
          isActive={is_active}
          className="absolute left-2 top-2 z-10"
        />
        <OfferDateScrapedBadge
          dateScraped={date_scraped}
          tag={Array.isArray(tag) ? tag[0] : tag}
          className="absolute bottom-2 left-2 z-10"
        />
      </div>
      <div className="absolute left-0 top-0 h-64 w-full md:h-48 md:w-64">
        <div className="relative h-full w-full">
          <span className="absolute right-1.5 top-1.5 z-10 rounded-full border-2 border-white">
            {issuer_type && (
              <IssuerTypeIcon
                issuerType={
                  Array.isArray(issuer_type) ? issuer_type[0] : issuer_type
                }
                size="md"
                showTooltip={true}
              />
            )}
          </span>
          {avm !== null &&
            price_per_meter !== null &&
            price_per_meter !== undefined && (
              <div className="absolute bottom-2 right-2 z-20">
                <OfferIndexBadge avm={avm} price_per_meter={price_per_meter} />
              </div>
            )}
        </div>
      </div>
      <div className="relative w-full p-3">
        <div className="grid h-full">
          <div className="grid">
            <div className="flex flex-row items-center truncate sm:row-start-1">
              <Link
                className="pointer-events-auto relative row-start-1 truncate text-lg leading-tight"
                onClick={(e) => {
                  e.preventDefault();
                  navigateToOffer();
                }}
                to={generateOfferLink({ id, offerType })}
              >
                {spam ? (
                  <OfferSpamIndicator
                    size="md"
                    className="absolute"
                    style={{ marginTop: '1px' }}
                  />
                ) : null}
                {contact_restriction ? (
                  <OfferContactRestrictionIndicator
                    size="md"
                    className="absolute"
                    style={{ marginTop: '1px' }}
                  />
                ) : null}
                <h1
                  className={`${
                    spam && contact_restriction
                      ? 'indent-12 '
                      : spam || contact_restriction
                      ? 'indent-6'
                      : ''
                  } truncate text-lg font-semibold leading-tight`}
                >
                  {title}
                </h1>
              </Link>
            </div>
            <div className="row-start-2 my-1 flex items-center sm:row-start-1 sm:my-0 sm:ml-2 sm:justify-self-end">
              <div className="mr-3">
                {assignment ? (
                  <OfferAssignmentBadge
                    extensions={showTimer ? ['WITH_TIMER'] : []}
                    variant="condense"
                    badgeContainerClassName="max-w-[140px] sm:max-w-[200px] overflow-hidden xl:max-w-[300px]"
                    badgeTextClassName="truncate"
                    assignment={assignment}
                    showBadgePrefix={isSm}
                  />
                ) : user.hasFeatureFlag(FeatureFlag.ALWAYS_DISABLE) ? (
                  <span
                    className="cursor-pointer text-gray-400"
                    onClick={() => assignOffer({ offerId: id })}
                  >
                    <UserGroupIcon className="h-5 w-5" />
                  </span>
                ) : null}
              </div>
              <ActivityIndicator mostRelevantActivity={activity || {}} />
              {noActivity(activity) && (
                <span
                  className="cursor-pointer text-gray-400"
                  onClick={markForContact}
                >
                  <PhoneIcon className="m-0.5 h-5 w-5" aria-hidden="true" />
                </span>
              )}
              <div className="ml-3 cursor-pointer">
                <OfferLikeIcon
                  id={id}
                  offerType={offerType}
                  isSaved={isSaved}
                  size="sm"
                />
              </div>
              <ContactNumberButton
                className="ml-3 block sm:hidden"
                textClassName="text-xs"
                offerId={id}
                offerOriginalUrl={offer_original_url}
                contactNumber={contact_number ?? undefined}
                hasPrefix={!contact_number}
              />
            </div>
          </div>
          <div className="mt-1 text-xs font-semibold uppercase tracking-wide text-gray-700">
            {[
              [
                floor_no_lower_bound !== null &&
                floor_no_lower_bound !== undefined
                  ? `${floor_no_lower_bound}${
                      floor_no_upper_bound !== floor_no_lower_bound ? '+' : ''
                    }`
                  : null,
                t('common:offerDetails.level'),
              ],
              [rooms, t('common:offerDetails.rooms')],
              [bathroom_count, t('common:offerDetails.bathrooms')],
              [year_built, t('common:offerDetails.built')],
              [
                land_type
                  ? t(
                      `data-model:enumLabels.land_type.${
                        Array.isArray(land_type) ? land_type[0] : land_type
                      }`,
                    )
                  : null,
                '',
              ],
              [
                land_shape
                  ? t(
                      `data-model:enumLabels.land_shape.${
                        Array.isArray(land_shape) ? land_shape[0] : land_shape
                      }`,
                    )
                  : null,
                '',
              ],
            ]
              .filter(([field]) => field != null)
              .map((field) => field.join(' '))
              .join(' • ')}
          </div>
          <div
            className="mt-1 line-clamp-3 text-sm text-gray-600"
            style={{ height: '60px' }}
          >
            {description_clean_text}
          </div>
          <div className="mt-auto flex flex-col">
            <div className="mb-2 flex flex-col items-start pt-1 md:flex-row md:items-center">
              <div className="mr-2 text-xs font-semibold text-gray-600">
                {formatAddress({
                  address_street,
                  address_street_number,
                  address_district,
                  address_city,
                  address_state,
                  address_county,
                  address_community,
                })}
              </div>
              <ContactNumberButton
                className="hidden px-1 py-0.5 sm:block"
                textClassName="text-xs"
                offerId={id}
                offerOriginalUrl={offer_original_url}
                contactNumber={contact_number ?? undefined}
                hasPrefix={!contact_number}
              />
            </div>
            <div className="mr-12 flex flex-row">
              {price !== null && price_currency && (
                <div className="flex space-x-3 text-sm sm:text-base">
                  <div>
                    <CurrencyFormatter
                      value={price}
                      currency={price_currency}
                    />
                  </div>
                  {previous_price !== null && previous_price !== undefined ? (
                    <OfferPriceChangeBadge
                      size="sm"
                      price={price}
                      previousPrice={previous_price}
                    />
                  ) : null}
                </div>
              )}
              {property_size !== null && property_size !== undefined ? (
                <div className="ml-3 border-l border-gray-300 pl-3 text-sm sm:text-base">
                  <SizeFormatter
                    {...getValueAndUnit(property_size, offerType)}
                  />
                </div>
              ) : null}
              {property_size !== null &&
                property_size !== undefined &&
                price_currency && (
                  <div className="ml-3 hidden border-l border-gray-300 pl-3 text-sm sm:block sm:text-base">
                    <CurrencyPerSizeFormatter
                      currencyUnit={price_currency}
                      currencyValue={price}
                      sizeUnit="square-meter"
                      sizeValue={property_size}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="absolute bottom-3 right-3 flex flex-row items-center">
          <OfferTypeIcon
            offerType={offerType}
            size="md"
            className="mr-1 sm:mr-2"
          />
          {duplicatesCount > 0 && (
            <OfferDuplicatesPopover
              duplicatesCount={duplicatesCount}
              offerId={id}
              offerType={offerType}
              className="mr-1 sm:mr-2"
            />
          )}
          <ActivityIcon
            count={activityCount}
            offerId={id}
            offerType={offerType}
            className="mr-1 sm:mr-2"
          />
          <OfferCardMenu
            contactNumber={
              Array.isArray(contact_number) ? contact_number[0] : contact_number
            }
            selectedOfferStatus={offerStatus}
            id={id}
            offerType={offerType}
            offerOriginalUrl={offer_original_url}
            downloadPdfFileName={title}
            onClickAddToClientSearch={() =>
              setIsAddToClientSearchPanelOpen(true)
            }
            displayAddToClientSearch={true}
            displayCreateMeeting={canUserUseMeetings}
            onClickCreateMeeting={() => setIsCreateMeetingPanelOpen(true)}
          />
        </div>
      </div>
      <MeetingCreateDrawer
        offerId={id}
        isOpen={isCreateMeetingPanelOpen}
        close={() => setIsCreateMeetingPanelOpen(false)}
      />
      <AddToClientSearchDrawer
        isOpen={isAddToClientSearchPanelOpen}
        close={() => setIsAddToClientSearchPanelOpen(false)}
        offerType={offerType}
        offerId={id}
      />
    </div>
  );
};

export default OfferCard;

const noActivity = (activity?: MostRelevantActivity) => {
  return (
    /* == is here for a reason, it catches both null and undefined (and only those values) */
    !activity || (activity.offer_status == null && activity.call_result == null)
  );
};
