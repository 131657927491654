import React from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import {
  FeatureFlag,
  OfferStatus,
  OfferStatusResponse,
  OfferTypeEnum,
} from '../../api/api.types';
import { usePopper } from 'react-popper';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import CopyOriginalOfferLinkAction from './offer-actions/actions/copy-orginal-offer-link-action';
import OpenOriginalOfferAction from './offer-actions/actions/open-original-offer-action';
import DownloadAsPdfAction from './offer-actions/actions/download-as-pdf-action';
import AddToStatusAction from './offer-actions/actions/add-to-status-action';
import { useAnalytics } from '../../context/analytics/use-analytics';
import CallAction from './offer-actions/actions/call-action';
import AddToClientSearchAction from './offer-actions/actions/add-to-client-search-action';
import AssignAction from './offer-actions/actions/assign-action';
import { useUser } from '../../context/auth/use-user';
import CreateMeetingAction from './offer-actions/actions/create-meeting-action';

interface Props {
  offerOriginalUrl?: string;
  id: string;
  offerType: OfferTypeEnum;
  selectedOfferStatus?: OfferStatusResponse;
  showStatuses?: boolean;
  contactNumber?: string | null;
  showDownloadPdf?: boolean;
  showAssignment?: boolean;
  downloadPdfFileName?: string;
  strategy?: 'fixed' | 'absolute';
  displayAddToClientSearch?: boolean;
  onClickAddToClientSearch?: () => void;
  displayCreateMeeting?: boolean;
  onClickCreateMeeting?: () => void;
}

const OfferCardMenu = ({
  offerOriginalUrl,
  id,
  offerType,
  selectedOfferStatus,
  contactNumber,
  showAssignment = true,
  showStatuses = true,
  strategy = 'fixed',
  showDownloadPdf = true,
  downloadPdfFileName = 'offer',
  displayAddToClientSearch = false,
  onClickAddToClientSearch = () => {},
  displayCreateMeeting = false,
  onClickCreateMeeting = () => {},
}: Props) => {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [active, setActive] = React.useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy,
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'eventListeners', enabled: active },
    ],
  });
  const analytics = useAnalytics();
  const user = useUser();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <>
        <Menu.Button
          ref={setReferenceElement}
          className="flex items-center rounded-full text-gray-400 hover:text-gray-600"
        >
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <div
          style={styles.popper}
          ref={setPopperElement}
          {...attributes.popper}
          className="z-30 w-56"
        >
          <Transition
            beforeEnter={() => {
              setActive(true);
              analytics.track(ANALYTICS_EVENTS.OFFER_MENU_OPENED, {
                offerType,
                offerId: id,
              });
            }}
            as={React.Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            afterLeave={() => setActive(false)}
          >
            <Menu.Items className="divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {offerOriginalUrl && (
                  <OpenOriginalOfferAction
                    offerOriginalUrl={offerOriginalUrl}
                  />
                )}
                {offerOriginalUrl && (
                  <CopyOriginalOfferLinkAction
                    offerOriginalUrl={offerOriginalUrl}
                  />
                )}
                {contactNumber && <CallAction contactNumber={contactNumber} />}
                {showDownloadPdf && (
                  <DownloadAsPdfAction
                    offerType={offerType}
                    offerId={id}
                    offerTitle={downloadPdfFileName}
                  />
                )}
                {displayCreateMeeting && (
                  <CreateMeetingAction onClick={onClickCreateMeeting} />
                )}
                {displayAddToClientSearch && (
                  <AddToClientSearchAction onClick={onClickAddToClientSearch} />
                )}
              </div>
              <div className="py-1">
                {showStatuses && (
                  <AddToStatusAction
                    offerType={offerType}
                    offerId={id}
                    selectedOfferStatus={selectedOfferStatus ?? null}
                    status={OfferStatus.MARKED_FOR_CONTACT}
                  />
                )}
                {showAssignment &&
                  user.hasFeatureFlag(FeatureFlag.ALWAYS_DISABLE) && (
                    <AssignAction offerId={id} />
                  )}
              </div>
            </Menu.Items>
          </Transition>
        </div>
      </>
    </Menu>
  );
};

export default OfferCardMenu;
