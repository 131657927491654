import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import {
  Bars3Icon,
  BookOpenIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  CloudIcon,
  Cog8ToothIcon,
  CurrencyDollarIcon,
  HeartIcon,
  HomeIcon,
  InboxIcon,
  KeyIcon,
  PhoneArrowUpRightIcon,
  PhoneIcon,
  UsersIcon,
  ViewColumnsIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Link, useLocation } from 'react-router-dom';
import NavItem from './nav-item';
import AppProgressBar from '../app-progress-bar/app-progress-bar';
import UserMenu from './user-menu';
import { animated, useSpring } from '@react-spring/web';
import { ScrollProvider } from '../../context/scroll/scroll';
import logoFull from '../../assets/logo-transparent.png';
import logoFullAnalytics from '../../assets/logo-full-analytics.png';
import { useMutation } from '@tanstack/react-query';
import { putSettings } from '../../api/auth/auth';
import { useUser } from '../../context/auth/use-user';
import { useLg } from '../../style/media-queries';
import { AppView, useAppView } from '../../context/app-views/use-app-view';
import Spinner from '../spinner/spinner';
import { RiContactsBook2Fill } from 'react-icons/all';
import { IconType } from 'react-icons';
import { FeatureFlag } from '../../api/api.types';
import { SubscriptionTierType } from '../../models/user';
import AppSelectPopover from './app-select-popover';
import { SideNavBarWidthProvider } from '../../context/width/side-nav-bar-width';

const NAV_ITEMS: NavElement[] = [
  {
    name: 'dashboard',
    to: '/dashboard',
    pathPrefix: '/dashboard',
    icon: HomeIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP, AppView.ANALYTICS_APP],
    requiredSubscriptionTier: SubscriptionTierType.BASIC,
  },
  {
    name: 'analytics',
    to: '/analytics',
    pathPrefix: '/analytics',
    icon: ChartBarIcon,
    availableInAppViews: [AppView.ANALYTICS_APP],
  },
  {
    name: 'offers',
    to: '/offers/category-select',
    pathPrefix: '/offers',
    icon: BuildingOfficeIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP, AppView.ANALYTICS_APP],
    requiredSubscriptionTier: SubscriptionTierType.BASIC,
  },
  {
    name: 'views',
    to: '/views',
    pathPrefix: 'views',
    icon: ViewColumnsIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP, AppView.ANALYTICS_APP],
    requiredSubscriptionTier: SubscriptionTierType.BASIC,
  },
  {
    name: 'dataSets',
    to: '/data-sets',
    pathPrefix: 'data-sets',
    icon: CloudIcon,
    availableInAppViews: [AppView.ANALYTICS_APP],
    requiredSubscriptionTier: SubscriptionTierType.BASIC,
  },
  {
    name: 'clientSearches',
    to: '/client-searches',
    pathPrefix: '/client-searches',
    icon: BriefcaseIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredSubscriptionTier: SubscriptionTierType.BASIC,
  },
  {
    name: 'smsCampaigns',
    to: '/sms-campaigns',
    pathPrefix: '/sms-campaigns',
    icon: InboxIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredFeatureFlag: FeatureFlag.SMS_CAMPAIGNS_ENABLED,
  },
  {
    name: 'salesAssistant',
    to: '/sales-assistant',
    pathPrefix: '/sales-assistant',
    icon: PhoneArrowUpRightIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredSubscriptionTier: SubscriptionTierType.ENTERPRISE,
  },
  {
    name: 'callList',
    to: '/call-list/personal',
    pathPrefix: '/call-list',
    icon: PhoneIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredSubscriptionTier: SubscriptionTierType.BASIC,
  },
  {
    name: 'avm',
    to: '/avm',
    pathPrefix: '/avm',
    icon: CurrencyDollarIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP, AppView.ANALYTICS_APP],
    requiredSubscriptionTier: SubscriptionTierType.PREMIUM,
  },
  {
    name: 'saved',
    to: '/saved-offers',
    pathPrefix: 'saved-offers',
    icon: HeartIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredSubscriptionTier: SubscriptionTierType.BASIC,
  },
  {
    name: 'myOffers',
    to: '/my-offers/personal',
    pathPrefix: 'my-offers',
    icon: KeyIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredSubscriptionTier: SubscriptionTierType.BASIC,
  },
  {
    name: 'offerExport',
    to: '/offer-export',
    pathPrefix: 'offer-export',
    icon: CloudIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredSubscriptionTier: SubscriptionTierType.PREMIUM,
    requiredFeatureFlag: FeatureFlag.OFFER_EXPORT_ENABLED,
  },
  {
    name: 'monitoredBooks',
    to: '/monitored-books',
    pathPrefix: 'monitored-books',
    icon: BookOpenIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredSubscriptionTier: SubscriptionTierType.PREMIUM,
  },
  {
    name: 'meetings',
    to: '/meetings',
    pathPrefix: 'meetings',
    icon: CalendarDaysIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredFeatureFlag: FeatureFlag.MEETINGS_ENABLED,
    requiredSubscriptionTier: SubscriptionTierType.PREMIUM,
  },
  {
    name: 'clients',
    to: '/clients',
    pathPrefix: '/clients',
    icon: RiContactsBook2Fill,
    availableInAppViews: [AppView.REAL_ESTATE_APP],
    requiredSubscriptionTier: SubscriptionTierType.BASIC,
  },
  {
    name: 'organization',
    to: '/organization/users',
    pathPrefix: '/organization',
    icon: UsersIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP, AppView.ANALYTICS_APP],
  },
  {
    name: 'settings',
    to: '/settings/account',
    pathPrefix: '/settings',
    icon: Cog8ToothIcon,
    availableInAppViews: [AppView.REAL_ESTATE_APP, AppView.ANALYTICS_APP],
  },
];

export interface NavElement {
  name: string;
  to: string;
  pathPrefix: string;
  icon:
    | React.ForwardRefExoticComponent<
        React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
          title?: string;
          titleId?: string;
        } & React.RefAttributes<SVGSVGElement>
      >
    | IconType;
  availableInAppViews: AppView[];
  requiredFeatureFlag?: FeatureFlag;
  requiredSubscriptionTier?: SubscriptionTierType;
}

interface Props {
  bgClassName?: string;
  contentWrapperClassName?: string;
  children: React.ReactNode;
}

export const Navigation: React.FC<Props> = ({
  children,
  bgClassName = '',
  contentWrapperClassName = '',
}) => {
  const user = useUser();
  const appView = useAppView();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [sidebarSmall, setSidebarSmall] = React.useState(
    user.settings.isSidebarSmall,
  );
  const [runSidebarAnimation, setRunSidebarAnimation] = React.useState(false);
  const location = useLocation();
  const scrollContainer = React.useRef<HTMLDivElement>(
    document.querySelector('html') as any,
  );

  const { mutateAsync: saveSettings } = useMutation(putSettings);

  React.useEffect(() => {
    scrollContainer.current?.scrollTo(
      0,
      (location?.state as any)?.restoreScrollY ?? 0,
    );
  }, [location.pathname, location.search]);

  const drawerStyles = useSpring({
    from: {
      width: sidebarSmall ? '16rem' : '3.5rem',
    },
    to: {
      width: sidebarSmall ? '3.5rem' : '16rem',
    },
    immediate: !runSidebarAnimation,
  });

  const filteredNavItems = NAV_ITEMS.filter((item) =>
    appView.viewMatchesAny(item.availableInAppViews),
  ).filter(
    (item) =>
      !item.requiredFeatureFlag ||
      user.hasFeatureFlag(item.requiredFeatureFlag),
  );

  const logo = appView.isAnalyticsApp ? logoFullAnalytics : logoFull;
  const isLg = useLg();

  return (
    <div className="grid h-full">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full flex-1 flex-col bg-white pb-4 pt-5">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-14 p-1">
                  <button
                    type="button"
                    className="flex h-12 w-12 items-center justify-center rounded-full focus:bg-gray-600 focus:outline-none"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Close sidebar</span>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex h-0 flex-1 flex-col overflow-y-hidden">
                <AppSelectPopover isSidebarSmall={false} center={false} />
                <nav className="mt-2 flex flex-1 flex-col space-y-1 overflow-y-scroll">
                  {filteredNavItems.map((item) => (
                    <NavItem
                      onClick={() => setSidebarOpen(false)}
                      item={item}
                      pathname={location.pathname}
                      key={item.to}
                    />
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true" />
        </Dialog>
      </Transition.Root>
      <div className="fixed bottom-0 top-0 z-50 hidden lg:flex lg:flex-shrink-0">
        <animated.div
          className="flex flex-col"
          style={{
            width: drawerStyles.width,
          }}
        >
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div
              id="main-side-navigation"
              className="relative flex flex-1 flex-col"
            >
              <AppSelectPopover isSidebarSmall={sidebarSmall} />
              <animated.div
                style={{
                  width: drawerStyles.width,
                }}
              >
                <nav className="flex-1 space-y-1 overflow-y-scroll border-r border-gray-200 bg-white">
                  {filteredNavItems.map((item) => (
                    <NavItem
                      item={item}
                      pathname={location.pathname}
                      key={item.to}
                    />
                  ))}
                </nav>
              </animated.div>
              <button
                type="button"
                className="bottom-2 right-2 mb-2 ml-auto mr-2 mt-2 mt-auto w-min cursor-pointer border-gray-200 p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => {
                  setSidebarSmall(!sidebarSmall);
                  setRunSidebarAnimation(true);
                  saveSettings({
                    first_name: user.firstName,
                    last_name: user.lastName,
                    email: user.email,
                    settings: {
                      ...user.settings,
                      isSidebarSmall: !sidebarSmall,
                    },
                    job_title: user.jobTitle,
                  });
                }}
              >
                {sidebarSmall ? (
                  <ChevronDoubleRightIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronDoubleLeftIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </animated.div>
      </div>
      <div className=" flex w-full flex-col focus:outline-none">
        <animated.div
          className="fixed left-0 right-0 top-0 z-40 flex h-12 flex-shrink-0 border-b border-gray-200 bg-white md:h-16"
          style={{
            paddingLeft: isLg ? drawerStyles.width : '0px',
          }}
        >
          <div className="mx-auto flex max-w-7xl flex-1 justify-between px-2">
            <div className="ml-2 flex items-center md:hidden">
              <Link
                className="flex flex-shrink-0 cursor-pointer items-center pb-2 pt-2"
                to="/dashboard"
              >
                <img
                  className="h-8 w-auto cursor-pointer"
                  src={logo}
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="flex flex-1">
              <div id="nav-top-bar-portal" className="w-full" />
            </div>
            <div className="mr-2 flex items-center md:mr-4">
              <UserMenu />
            </div>
            <button
              type="button"
              className="px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <Bars3Icon className="h-7 w-7" aria-hidden="true" />
            </button>
          </div>
        </animated.div>
        <animated.div
          id="nav-below-top-bar-portal"
          className="fixed left-0 right-0 top-12 z-30 md:top-16"
          style={{
            paddingLeft: isLg ? drawerStyles.width : '0px',
          }}
        />
        <AppProgressBar />
        <animated.main
          style={{
            paddingLeft: isLg ? drawerStyles.width : '0px',
          }}
          className={`h-full w-full ${bgClassName} mt-12 md:mt-16`}
        >
          <ScrollProvider scrollContainer={scrollContainer}>
            <SideNavBarWidthProvider>
              <React.Suspense fallback={<Spinner />}>
                <div
                  className={`mx-auto h-full max-w-7xl ${contentWrapperClassName}`}
                >
                  {children}
                </div>
              </React.Suspense>
            </SideNavBarWidthProvider>
          </ScrollProvider>
        </animated.main>
        <animated.div
          id="nav-bottom-bar-portal"
          className="fixed bottom-0 left-0 right-0 z-30"
          style={{
            paddingLeft: isLg ? drawerStyles.width : '0px',
          }}
        />
      </div>
    </div>
  );
};

export default Navigation;
