import { axios } from '../axios';
import {
  ActivityStatusResponse,
  AddOfferStatusRequest,
  AddOfferStatusResponse,
  CallListOfferResponse,
  CallListType,
  ClientOfferSearchListItemForOfferDetails,
  DuplicateOfferResponse,
  HistogramViewResult,
  MapOfferListResponse,
  OfferCountResult,
  OfferDetailsResponse,
  OfferListResponse,
  OfferStatus,
  OfferStatusDetailsResponse,
  OfferTypeEnum,
  OfferWithStatusListResponse,
  SavedOfferResponse,
} from '../api.types';
import { Filter } from '../../models/filter';
import { Sort } from '../../models/sort';
import { stringify } from 'query-string';
import { MapBounds } from '../../hooks/use-offers/use-search-map-offers-query';

export const getMapOffers = (
  params: {
    bounds: MapBounds;
    offerType: OfferTypeEnum[];
    filters: Filter[];
    sorts: Sort[];
    size: number;
  },
  { signal }: { signal?: AbortSignal } = {},
): Promise<MapOfferListResponse> => {
  return axios
    .post(
      `/offers/map`,
      {
        size: params.size,
        lat_min: params.bounds.latMin,
        lat_max: params.bounds.latMax,
        lon_min: params.bounds.lonMin,
        lon_max: params.bounds.lonMax,
        offer_type: params.offerType,
        filters: JSON.stringify(params.filters),
        sorts: JSON.stringify(params.sorts),
      },
      {
        signal,
      },
    )
    .then((resp) => resp.data);
};

export const getOffers = (
  offerType: OfferTypeEnum[],
  page: number,
  size: number = 35,
  filters: Filter[] = [],
  sorts: Sort[] = [],
  { signal }: { signal?: AbortSignal } = {},
): Promise<OfferListResponse> => {
  return axios
    .post(
      '/offers',
      {
        page,
        size,
        offer_type: JSON.stringify(offerType),
        filters: JSON.stringify(filters),
        sorts: JSON.stringify(sorts),
      },
      {
        signal,
      },
    )
    .then((resp) => resp.data);
};

export const getOffersCount = (
  offerTypes: OfferTypeEnum[],
  filters: Filter[] = [],
  { signal }: { signal?: AbortSignal } = {},
): Promise<OfferCountResult> => {
  return axios
    .post(
      `/offers/count`,
      {
        filters: JSON.stringify(filters),
        offer_types: JSON.stringify(offerTypes),
      },
      {
        signal,
      },
    )
    .then((resp) => resp.data);
};

export const getOffersHistogram = (
  offerTypes: OfferTypeEnum[],
  filters: Filter[] = [],
  { signal }: { signal?: AbortSignal } = {},
): Promise<HistogramViewResult[]> => {
  return axios
    .get(`/offers/histogram`, {
      params: {
        filters: JSON.stringify(filters),
        offer_type: offerTypes,
      },
      signal,
      paramsSerializer: (params) => {
        return stringify(params);
      },
    })
    .then((resp) => resp.data);
};

export const getOfferByTypeAndId = (
  offerType: OfferTypeEnum | 'OFFERS',
  offerId: String,
): Promise<OfferDetailsResponse> => {
  return axios.get(`/offers/${offerType}/${offerId}`).then((resp) => resp.data);
};

export const getByUrl = (
  offerOriginalUrl: string,
): Promise<OfferDetailsResponse> => {
  return axios
    .get(`/offers/url`, { params: { url: offerOriginalUrl } })
    .then((resp) => resp.data);
};

export const postOfferStatus = ({
  body,
  offerId,
  offerType,
}: {
  body: AddOfferStatusRequest;
  offerId: string;
  offerType: OfferTypeEnum;
}): Promise<AddOfferStatusResponse> => {
  return axios
    .post(`/offers/${offerType}/${offerId}/statuses`, body)
    .then((resp) => resp.data);
};

export const deleteOfferStatus = ({
  offerId,
  offerType,
}: {
  offerId: string;
  offerType: OfferTypeEnum;
}): Promise<void> => {
  return axios.delete(`/offers/${offerType}/${offerId}/statuses`);
};

export const getStatusesForOffer = (
  offerId: string,
  offerType: OfferTypeEnum,
): Promise<OfferStatusDetailsResponse[]> => {
  return axios
    .get(`/offers/${offerType}/${offerId}/statuses`)
    .then((resp) => resp.data);
};

export const postSaveOffer = ({
  offerId,
  offerType,
}: {
  offerId: string;
  offerType: OfferTypeEnum;
}): Promise<void> => {
  return axios.post(`/saved-offers/${offerType}/${offerId}`);
};

export const deleteSavedOffer = ({
  offerId,
  offerType,
}: {
  offerId: string;
  offerType: OfferTypeEnum;
}): Promise<void> => {
  return axios.delete(`/saved-offers/${offerType}/${offerId}`);
};

export const getSavedOffers = (
  {
    offerType,
    page,
    size,
  }: {
    offerType?: OfferTypeEnum;
    page: number;
    size: number;
  },
  { signal }: { signal?: AbortSignal } = {},
): Promise<OfferListResponse> => {
  return axios
    .get<OfferListResponse>(`/saved-offers`, {
      params: { page, size, offer_type: offerType },
      signal,
    })
    .then((resp) => resp.data);
};

export const getSavedOfferStatus = ({
  offerType,
  offerId,
}: {
  offerType: OfferTypeEnum;
  offerId: string;
}) => {
  return axios
    .get<SavedOfferResponse>(`/saved-offers/${offerType}/${offerId}`)
    .then((resp) => resp.data);
};

export const getCallListOffers = ({
  callListType,
}: {
  callListType: CallListType;
}): Promise<CallListOfferResponse[]> => {
  return axios
    .get<CallListOfferResponse[]>(
      `/call-list-offers`,

      { params: { call_list_type: callListType } },
    )
    .then((resp) => resp.data);
};

export const getOffersWithActiveStatus = ({
  offerType,
  status,
  page,
  size,
}: {
  offerType?: OfferTypeEnum;
  status?: OfferStatus;
  page: number;
  size: number;
}): Promise<OfferWithStatusListResponse> => {
  return axios
    .get<OfferWithStatusListResponse>('/offers-with-active-status', {
      params: {
        offer_type: offerType,
        offer_status: status,
        page,
        size,
      },
    })
    .then((resp) => resp.data);
};

export const getOffersWithArchivedStatus = (
  {
    offerType,
    status,
    page,
    size,
  }: {
    offerType?: OfferTypeEnum;
    status?: OfferStatus;
    page: number;
    size: number;
  },
  { signal }: { signal?: AbortSignal } = {},
): Promise<OfferWithStatusListResponse> => {
  return axios
    .get<OfferWithStatusListResponse>('/offers-with-archived-status', {
      params: {
        offer_type: offerType,
        offer_status: status,
        page,
        size,
      },
      signal,
    })
    .then((resp) => resp.data);
};

export const getDuplicatesForOffer = (
  offerType: OfferTypeEnum,
  offerId: string,
): Promise<DuplicateOfferResponse[]> => {
  return axios
    .get<DuplicateOfferResponse[]>(`/offers/${offerType}/${offerId}/duplicates`)
    .then((resp) => resp.data);
};

export const getActivityForOffer = (
  offerType: OfferTypeEnum,
  offerId: string,
): Promise<ActivityStatusResponse[]> => {
  return axios
    .get<ActivityStatusResponse[]>(`/offers/${offerType}/${offerId}/activity`)
    .then((resp) => resp.data);
};

export const getClientSearchesForOffer = (
  offerType: OfferTypeEnum,
  offerId: string,
): Promise<ClientOfferSearchListItemForOfferDetails[]> => {
  return axios
    .get<ClientOfferSearchListItemForOfferDetails[]>(
      `/offers/${offerType}/${offerId}/offerSearches`,
    )
    .then((resp) => resp.data);
};

export const archiveOffer = ({
  offerId,
  offerType,
}: {
  offerId: string;
  offerType: OfferTypeEnum;
}): Promise<void> => {
  return axios.post(`/offers/${offerType}/${offerId}/statuses/archive`);
};

export const archiveOfferStatuses = (statusIds: string[]): Promise<void> => {
  return axios.post(`/offer-statuses/archive`, { status_ids: statusIds });
};
