import React from 'react';
import { OfferStatus } from '../../api/api.types';
import { OptimisticUpdateManagerContext } from './use-optimistic-update-manager';
import { OptimisticUpdatesContext } from './use-optimistic-updates';

export interface OptimisticOfferUpdate {
  isLiked?: boolean;
  status?: { status: OfferStatus | null; isArchived: boolean | null };
}

export const OfferOptimisticUpdateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, setState] = React.useState<{
    [key: string]: OptimisticOfferUpdate;
  }>({});

  const setLikedStatus = React.useCallback(
    (offerId: string, likedStatus: boolean) => {
      setState((prevState) => ({
        ...prevState,
        [offerId]: { ...prevState[offerId], isLiked: likedStatus },
      }));
    },
    [],
  );

  const removeLikedStatus = React.useCallback((offerId: string) => {
    setState((prevState) => ({
      ...prevState,
      [offerId]: { ...prevState[offerId], isLiked: undefined },
    }));
  }, []);

  const removeAllLikedStatuses = React.useCallback(() => {
    setState((prevState) => {
      return Object.entries(prevState).reduce((acc, [key, value]) => {
        acc[key] = { ...value, isLiked: undefined };
        return acc;
      }, {} as { [key: string]: OptimisticOfferUpdate });
    });
  }, []);

  const removeAllOfferStatuses = React.useCallback(() => {
    setState((prevState) => {
      return Object.entries(prevState).reduce((acc, [key, value]) => {
        acc[key] = { ...value, status: undefined };
        return acc;
      }, {} as { [key: string]: OptimisticOfferUpdate });
    });
  }, []);

  const setOfferStatus = React.useCallback(
    (
      offerId: string,
      status: OfferStatus | null,
      isArchived: boolean | null,
    ) => {
      setState((prevState) => ({
        ...prevState,
        [offerId]: {
          ...prevState[offerId],
          status: {
            status,
            isArchived,
          },
        },
      }));
    },
    [],
  );

  const removeOfferStatus = React.useCallback((offerId: string) => {
    setState((prevState) => ({
      ...prevState,
      [offerId]: { ...prevState[offerId], status: undefined },
    }));
  }, []);

  const optimisticLikeForOffer = React.useCallback(
    (offerId: string): boolean | undefined => {
      const offer = state[offerId];
      if (!offer) {
        return undefined;
      }
      return offer.isLiked;
    },
    [state],
  );

  const optimisticStatusForOffer = React.useCallback(
    (
      offerId: string,
    ):
      | { status: OfferStatus | null; isArchived: boolean | null }
      | undefined => {
      const offer = state[offerId];
      if (!offer) {
        return undefined;
      }
      return offer.status;
    },
    [state],
  );

  const isOptimisticLikeForOffer = React.useCallback(
    (offerId: string) => {
      return optimisticLikeForOffer(offerId) !== undefined;
    },
    [optimisticLikeForOffer],
  );

  const isOptimisticStatusForOffer = React.useCallback(
    (offerId: string) => {
      return optimisticStatusForOffer(offerId) !== undefined;
    },
    [optimisticStatusForOffer],
  );

  return (
    <OptimisticUpdateManagerContext.Provider
      value={{
        removeAllLikedStatuses,
        setLikedStatus,
        removeLikedStatus,
        setOfferStatus,
        removeOfferStatus,
        removeAllOfferStatuses,
      }}
    >
      <OptimisticUpdatesContext.Provider
        value={{
          optimisticLikeForOffer,
          optimisticStatusForOffer,
          isOptimisticLikeForOffer,
          isOptimisticStatusForOffer,
        }}
      >
        {children}
      </OptimisticUpdatesContext.Provider>
    </OptimisticUpdateManagerContext.Provider>
  );
};
